import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Query } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { QueryType } from '@wdx/clmi/api-services/services';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../../../../../models/pagination-options';
import { ApiPaginationService } from '../../../../../../services/api-pagination.service';
import { ConfigService } from '../../../../../../services/config.service';
import { EXPRESSIONS_LOOP } from '../../helper/helper-remove-name';

const QUERY = 'query';
@Injectable({
    providedIn: 'root',
})
export class FilterHttpService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getRecords<U>(
        queryType: QueryType,
        paginationOptions: PaginationOptions,
        filters: Query
    ): Observable<PaginatedApiResponse<U>> {
        const NEW_FILTERS = EXPRESSIONS_LOOP(filters);

        return this.http.post<PaginatedApiResponse<U>>(
            `${
                this.config.getConfiguration().API_BASE
            }/${QUERY}/${queryType.toLowerCase()}`,
            NEW_FILTERS || {},
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }
}

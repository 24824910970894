import { Location } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';
import { fileDownloadActions } from '@wdx/shared/infrastructure/file-io';
import {
    InterpolationFragment,
    InterpolationFragmentType,
} from '../../../models/notification.model';
import { EntityRoutePipe } from '../../../pipes/entity-route.pipe';
import * as rootReducer from '../../../state/_setup/reducers';

@Component({
    selector: 'clmi-interpolation-display',
    templateUrl: './interpolation-display.component.html',
})
export class InterpolationDisplayComponent implements OnInit {
    readonly NOTIFICATION_FRAGMENT_TYPE = InterpolationFragmentType;

    fragments: Array<InterpolationFragment>;

    @Input() entityId;
    @Input() text;

    @Output() linkSelected = new EventEmitter<void>();

    private store$ = inject(Store<rootReducer.State>);
    private location = inject(Location);
    private router = inject(Router);

    ngOnInit(): void {
        this.fragments = this.parseInterpolation(this.text);
    }

    onDownload(fragment: InterpolationFragment) {
        this.store$.dispatch(
            fileDownloadActions.getFileDownload({
                fileIndex: fragment.fileIndex,
                fileName: fragment.value,
            })
        );
    }

    private parseInterpolation(value: string): Array<InterpolationFragment> {
        return (
            value
                // split entire string (e.g. 'abc#I:II:III#def#1:2:3#...) by the pattern #a:b:c#
                ?.split(/#(.*?)#/gi)
                // remove empty ones
                .filter((fragment) => fragment)
                .map((fragment: string): InterpolationFragment => {
                    // further split fragments to a and b and c
                    const params = fragment.split(':');

                    // if this is a download notification
                    if (params[0] === 'fileindex') {
                        return {
                            type: InterpolationFragmentType.FileDownload,
                            value: params[1],
                            fileIndex: params[2],
                        };
                    }

                    // if this is a link notification
                    if (params.length >= 3) {
                        let entityType = params[
                            params.length - 1
                        ] as SystemEntity;
                        let entityId = params[params.length - 2];
                        let subEntityType = null;
                        let subEntityId = null;
                        const value = [...params]
                            .splice(0, params.length - 2)
                            .join(': ');

                        // if this is a work item, attach case as main item and parse fragments for sub item
                        if (params.includes(SystemEntity.WorkItem)) {
                            entityType = SystemEntity.Case;
                            entityId = this.entityId;
                            subEntityType = params[params.length - 1];
                            subEntityId = params[params.length - 2];
                        }

                        const url = new EntityRoutePipe(
                            this.location
                        ).transform(
                            entityType,
                            entityId,
                            null,
                            subEntityType,
                            subEntityId
                        );

                        return {
                            type: InterpolationFragmentType.Link,
                            value,
                            entityType,
                            entityId,
                            subEntityType,
                            subEntityId,
                            url,
                        };
                    }

                    // otherwise it's just a simple text
                    return {
                        type: InterpolationFragmentType.Text,
                        value: fragment,
                    };
                })
        );
    }

    onLinkSelected(): void {
        this.linkSelected.emit();
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import {
    SeverityPipe,
    TranslateEntityPipe,
    TranslatePipe,
} from '@wdx/shared/utils';
import { AtomAvatarModule } from '../../../components/atoms/atom-avatar/atom-avatar.module';
import { AtomSeverityIndicatorsModule } from '../../../components/atoms/atom-severity-indicators/atom-severity-indicators.model';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeContextMenuComponent } from './molecule-context-menu.component';

@NgModule({
    imports: [
        AtomAvatarModule,
        AtomSeverityIndicatorsModule,
        CommonModule,
        DirectivesModule,
        NgbDropdownModule,
        PipesModule,
        RouterModule,
        TranslateEntityPipe,
        TranslatePipe,
        WdxIconModule,
        WdxIconStackModule,
        SeverityPipe,
    ],
    declarations: [MoleculeContextMenuComponent],
    exports: [MoleculeContextMenuComponent],
})
export class MoleculeContextMenuModule {}

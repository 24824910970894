import { Injectable } from '@angular/core';
import { Query, SortDirection, SystemEntity, View } from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import { QueryType } from '@wdx/clmi/api-services/services';
import { LocalStorageKey, LocalStorageService } from '@wdx/shared/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterHandler } from '../../../../../../classes/filter-handler.class';
import { QueryExpressionExt } from '../../../../../../models/filter-type-types.model';
import { QueryBuilderFilterHandlerService } from '../filter-handler/filter-handler.service';
import { FilterQueryFacadeService } from '../filter-query-facade';
import { FILTER_QUERY_SEARCH } from './filter-query.static';

@Injectable()
export class FilterQueryService {
    buildQuery$ = new BehaviorSubject<any>(null);
    view$ = new BehaviorSubject<View>(null);
    view: View;
    viewType: SystemEntity;
    queryType: QueryType;
    query: Query;
    builderQuery: Query;
    isFilterHidden = false;
    isFilterHidden$ = new BehaviorSubject<boolean>(this.isFilterHidden);
    viewInEdit: View;
    inBuilder = false;
    paging: Paging;
    filterHandler: FilterHandler;
    updateTableHeader$ = new Subject<{ isFavourite: boolean }>();
    contextual: QueryExpressionExt[] = [];
    viewBuilderResults$ = new BehaviorSubject(false);

    get queryToUse(): Query {
        return this.builderQuery ? this.builderQuery : this.query;
    }

    constructor(
        private localStorageService: LocalStorageService,
        private filterQueryFacadeService: FilterQueryFacadeService,
        private qbFilterHandlerService: QueryBuilderFilterHandlerService
    ) {}

    setView(view: View): void {
        this.updateView(view);
    }

    updateView(view: View): void {
        this.view$.next(view);
        this.view = view;
        this.query = view?.filter;
    }

    getLocationStorageFilterVisibilityToggle(): void {
        const STATE = this.localStorageService.getStringKey(
            `${LocalStorageKey.DefaultViewId}-${this.viewType}-toggle`
        );

        let finalState = false;

        if (STATE === 'true') {
            finalState = true;
        }

        this.isFilterHidden = finalState;
        this.isFilterHidden$.next(finalState);
    }

    setLocationStorageFilterVisibilityToggle(): void {
        const STATE = !this.isFilterHidden;
        this.isFilterHidden = STATE;
        this.isFilterHidden$.next(STATE);
        this.localStorageService.setStringKey(
            `${LocalStorageKey.DefaultViewId}-${this.viewType}-toggle`,
            `${STATE}`
        );
    }

    sortQueryParams(queryData: { [key: string]: any }): void {
        if (!this.builderQuery && !this.viewInEdit) {
            this.sortQuery(queryData);

            this.filterQueryFacadeService.resetResults(this.queryType);
            this.filterQueryFacadeService.getResults(
                this.queryType,
                this.query,
                this.paging
            );
        }
    }

    sortQuery(queryData: { [key: string]: any }): void {
        let updatedSearch = false;

        this.query = {
            ...this.view?.filter,
            expressions: this.view?.filter?.expressions
                ? [...this.view.filter.expressions]
                : [],
        };

        this.qbFilterHandlerService.findFavouriteInExpression(
            this.view?.filter?.expressions,
            this.filterHandler
        );

        const HAS_SEARCH =
            this.qbFilterHandlerService.findPropInExpressionGetItem(
                this.view?.filter?.expressions,
                FILTER_QUERY_SEARCH
            );

        Object.keys(queryData).forEach((key) => {
            const res = this.qbFilterHandlerService.updateQueryData(
                this.query,
                key,
                queryData,
                updatedSearch
            );

            this.query = res.query;
            updatedSearch = res.updatedSearch;
        });

        if (!updatedSearch && HAS_SEARCH) {
            const expressions = this.qbFilterHandlerService.addEmptySearchValue(
                this.query
            );

            this.query = { ...this.query, expressions };
        }
    }

    toggleEditView(view: View): void {
        this.viewInEdit = view;
        this.inBuilder = true;
    }

    selectView(view: View): void {
        this.updateTableHeadersSorting(view);
        this.localStorageService.setStringKey(
            `${LocalStorageKey.DefaultViewId}-${this.viewType}`,
            view?.id
        );
        this.setView(view);
    }

    updateResults(paging: Paging): void {
        this.filterQueryFacadeService.updateResults(
            this.queryType,
            paging,
            this.queryToUse
        );
    }

    updateTableHeadersSorting(editData: View): void {
        this.filterHandler?.setSortBy(editData?.filter?.sortBy || null, false);

        this.qbFilterHandlerService.findFavouriteInExpression(
            editData?.filter?.expressions,
            this.filterHandler
        );

        const HAS_SEARCH =
            this.qbFilterHandlerService.findPropInExpressionGetItem(
                editData?.filter?.expressions,
                FILTER_QUERY_SEARCH
            );

        this.filterHandler?.setSortDirection(
            editData?.filter?.sortDirection || SortDirection.Ascending,
            false
        );

        this.filterHandler?.setSearchTextWithoutEmitting(
            editData?.filter && editData?.filter?.expressions
                ? editData?.filter?.expressions[HAS_SEARCH]?.values[0]
                : null
        );

        this.filterHandler?.setIsFavourite(
            this.qbFilterHandlerService.hasFavourite,
            false
        );
    }
}

import { Action, createReducer, on } from '@ngrx/store';
import {
    AccessVisibility,
    HistoryEntry,
    Case,
    CaseCycle,
    CaseTeamMember,
    WorkItemCategorySummary,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { FAVOURITES_INDEX_ID } from '../../constants/state.constants';
import { CaseWork } from '../../models/case-work.model';
import * as casesActions from './cases.actions';

export interface State {
    cases?: CrudStateObject<Case>;
    clientCases?: CrudStateObject<Case>;
    caseWork?: CrudStateObject<CaseWork>;
    caseHistory?: CrudStateObject<HistoryEntry>;
    assignees?: CrudStateObject<CaseTeamMember>;
    categories?: CrudStateObject<WorkItemCategorySummary>;
    visibility?: CrudStateObject<AccessVisibility>;
    reviews?: CrudStateObject<CaseCycle>;
}

export const initialState: State = {
    cases: {},
    clientCases: {},
    caseWork: {},
    caseHistory: {},
    assignees: {},
    categories: {},
    visibility: {},
    reviews: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        casesActions.getCases,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.entityId]: {
                    list: state.cases[props.entityId]?.list,
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCasesSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.entityId]: {
                    ...state.cases[props.entityId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases,
                },
            },
        })
    ),

    on(
        casesActions.getCasesFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.entityId]: {
                    ...state.cases[props.entityId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getCase,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...(state.cases[props.caseId] || ({} as CrudState<Case>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCaseSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.case,
                },
            },
        })
    ),

    on(
        casesActions.getCaseFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        casesActions.getCaseWork,
        (state, props): State => ({
            ...state,
            caseWork: {
                ...state.caseWork,
                [props.caseId]: {
                    ...(state.caseWork[props.caseId] ||
                        ({} as CrudState<CaseWork>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCaseWorkSuccess,
        (state, props): State => ({
            ...state,
            caseWork: {
                ...state.caseWork,
                [props.caseId]: {
                    ...state.caseWork[props.caseId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.caseWork,
                },
            },
        })
    ),

    on(
        casesActions.getCaseWorkFailure,
        (state, props): State => ({
            ...state,
            caseWork: {
                ...state.caseWork,
                [props.caseId]: {
                    ...state.caseWork[props.caseId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getCaseHistory,
        (state, props): State => ({
            ...state,
            caseHistory: {
                ...state.caseHistory,
                [props.caseId]: {
                    ...(state.caseHistory[props.caseId] ||
                        ({} as CrudState<any>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCaseHistorySuccess,
        (state, props): State => ({
            ...state,
            caseHistory: {
                ...state.caseHistory,
                [props.caseId]: {
                    ...state.caseHistory[props.caseId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.caseHistory,
                },
            },
        })
    ),

    on(
        casesActions.getCaseHistoryFailure,
        (state, props): State => ({
            ...state,
            caseHistory: {
                ...state.caseHistory,
                [props.caseId]: {
                    ...state.caseHistory[props.caseId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getCasesForClient,
        (state, props): State => ({
            ...state,
            clientCases: {
                ...state.clientCases,
                [props.clientId]: {
                    ...(state.clientCases[props.clientId] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCasesForClientSuccess,
        (state, props): State => ({
            ...state,
            clientCases: {
                ...state.clientCases,
                [props.clientId]: {
                    ...state.clientCases[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientCases,
                },
            },
        })
    ),

    on(
        casesActions.getCasesForClientFailure,
        (state, props): State => ({
            ...state,
            clientCases: {
                ...state.clientCases,
                [props.clientId]: {
                    ...state.clientCases[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getFavourites,
        (state): State => ({
            ...state,
            cases: {
                ...state.cases,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.cases[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getFavouritesSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.cases[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.favourites,
                },
            },
        })
    ),

    on(
        casesActions.getFavouritesFailure,
        (state): State => ({
            ...state,
            cases: {
                ...state.cases,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.cases[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.deleteTeamMember,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...(state.assignees[props.caseId] ||
                        ({} as CrudState<CaseTeamMember>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        casesActions.deleteTeamMemberSuccess,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...state.assignees[props.caseId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        casesActions.deleteTeamMemberFailure,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...state.assignees[props.caseId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        casesActions.getAllMembers,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...(state.assignees[props.caseId] ||
                        ({} as CrudState<CaseTeamMember>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getAllMembersSuccess,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...(state.assignees[props.caseId] ||
                        ({} as CrudState<CaseTeamMember>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.teamMembers,
                },
            },
        })
    ),

    on(
        casesActions.getAllMembersFailure,
        (state, props): State => ({
            ...state,
            assignees: {
                ...state.assignees,
                [props.caseId]: {
                    ...(state.assignees[props.caseId] ||
                        ({} as CrudState<CaseTeamMember>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.completeCase,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...(state.cases[props.caseId] || ({} as CrudState<Case>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        casesActions.completeCaseSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.case,
                },
            },
        })
    ),

    on(
        casesActions.completeCaseFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        casesActions.cancelCase,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...(state.cases[props.caseId] || ({} as CrudState<Case>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        casesActions.cancelCaseSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isUpdating: false,
                    hasUpdatingError: false,
                    single: props.case,
                },
            },
        })
    ),

    on(
        casesActions.cancelCaseFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.caseId]: {
                    ...state.cases[props.caseId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        casesActions.getCaseCategories,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.caseId]: {
                    ...(state.cases[props.caseId] ||
                        ({} as CrudState<WorkItemCategorySummary>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getCaseCategoriesSuccess,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.caseId]: {
                    ...(state.cases[props.caseId] ||
                        ({} as CrudState<WorkItemCategorySummary>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.categories,
                },
            },
        })
    ),

    on(
        casesActions.getCaseCategoriesFailure,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.caseId]: {
                    ...(state.cases[props.caseId] ||
                        ({} as CrudState<WorkItemCategorySummary>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getVisibilityForId,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...(state.visibility[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getVisibilityForIdSuccess,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        })
    ),

    on(
        casesActions.getVisibilityForIdFailure,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        casesActions.getReviewsForId,
        (state, props): State => ({
            ...state,
            reviews: {
                ...state.reviews,
                [props.id]: {
                    ...state.reviews[props.id],
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        casesActions.getReviewsForIdSuccess,
        (state, props): State => ({
            ...state,
            reviews: {
                ...state.reviews,
                [props.id]: {
                    ...state.reviews[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.reviews,
                },
            },
        })
    ),

    on(
        casesActions.getReviewsForIdFailure,
        (state, props): State => ({
            ...state,
            reviews: {
                ...state.reviews,
                [props.id]: {
                    ...state.reviews[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}

import { createAction, props } from '@ngrx/store';
import { Notification, Query } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { PaginationOptions } from '../../models/pagination-options';

export const getPage = createAction(
    '[Notifications] Get Page',
    props<{
        reset?: boolean;
        pageSize: number;
    }>()
);

export const getPageSuccess = createAction(
    '[Notifications] Get Page Success',
    props<{
        reset?: boolean;
        notifications: PaginatedApiResponse<Notification>;
    }>()
);

export const getPageFailure = createAction(
    '[Notifications] Get Page Failure',
    props<{
        error: Error;
    }>()
);

export const setUnreadNotifications = createAction(
    '[Notifications] Set Unread Notifications',
    props<{
        unreadNotifications: number;
    }>()
);

export const getForView = createAction(
    `[Notifications] Get For View`,
    props<{
        paginationOptions: PaginationOptions;
        filters: Query;
    }>()
);

export const getForViewSuccess = createAction(
    `[Notifications] Get For View Success`,
    props<{
        payload: PaginatedApiResponse<any>;
    }>()
);

export const getForViewFailure = createAction(
    `[Notifications] Get For View Failure`,
    props<{
        error: Error;
    }>()
);

export const markAsRead = createAction(
    '[Notifications] Mark As Read',
    props<{
        notifications: Notification[];
    }>()
);

export const markAsReadSuccess = createAction(
    '[Notifications] Mark As Read Success'
);

export const markAsReadFailure = createAction(
    '[Notifications] Mark As Read Failure',
    props<{
        error: Error;
    }>()
);

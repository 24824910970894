export const UTILS_ICON_CLONE = 'clone';
export const UTILS_ICON_CLOSE = 'times';
export const UTILS_ICON_ACTIVATE = 'paper-plane';
export const UTILS_ICON_DEACTIVATE = 'archive';
export const UTILS_ICON_DELETE = 'trash-alt';
export const UTILS_ICON_REFRESH = 'refresh';
export const UTILS_ICON_DROPDOWN = 'chevron-down';
export const UTILS_ICON_CHEVRON_UP = 'chevron-up';
export const UTILS_ICON_CHEVRON_RIGHT = 'chevron-right';
export const UTILS_ICON_DOCUMENT = 'file';
export const UTILS_ICON_FILE_CODE = 'file-code';
export const UTILS_ICON_FILE_CSV = 'file-csv';
export const UTILS_ICON_FILE_EXCEL = 'file-excel';
export const UTILS_ICON_FILE_IMAGE = 'file-image';
export const UTILS_ICON_FILE_PDF = 'file-pdf';
export const UTILS_ICON_FILE_POWERPOINT = 'file-powerpoint';
export const UTILS_ICON_FILE_WORD = 'file-word';
export const UTILS_ICON_FILE_PLUS = 'file-plus';
export const UTILS_ICON_DOWNLOAD = 'download';
export const UTILS_ICON_MINUS = 'minus';
export const UTILS_ICON_ADD = 'plus';
export const UTILS_ICON_USER = 'user';
export const UTILS_ICON_CIRCLE = 'circle';
export const UTILS_ICON_CHECK = 'check';
export const UTILS_ICON_INFO_CIRCLE = 'exclamation-circle';
export const UTILS_ICON_CONTEXT_MENU = 'ellipsis-h';
export const UTILS_ICON_QUESTION = 'question';
export const UTILS_ICON_MISSING = 'times-square';
export const UTILS_ICON_PENCIL = 'pencil';
export const UTILS_ICON_FAVOURITE = 'star';
export const UTILS_ICON_SUCCESS = 'check-circle';
export const UTILS_ICON_WARNING = 'exclamation-triangle';
export const UTILS_ICON_DANGER = 'exclamation-circle';
export const UTILS_ICON_CREDIT_CARD = 'credit-card';
export const UTILS_ICON_MESSAGES = 'messages';
export const UTILS_ICON_AUDIT = 'clock-rotate-left';
export const UTILS_ICON_DELETE_UNDO = 'trash-undo';
export const UTILS_ICON_ARROW_UPLOAD = 'cloud-arrow-up';
export const UTILS_ICON_ARROW_DOWNLOAD = 'cloud-arrow-down';
export const UTILS_ICON_EYE = 'eye';
export const UTILS_ICON_ANNOUNCEMENT = 'bullhorn';
export const UTILS_ICON_CALENDAR_CHECK = 'calendar-check';
export const UTILS_ICON_FILTER = 'filter';
export const UTILS_ICON_ENVELOPE_OPEN = 'envelope-open';
export const UTILS_ICON_ENVELOPE_DOT = 'envelope-dot';
export const UTILS_ICON_INFO = 'info-circle';
export const UTILS_ICON_HOME = 'home';
export const UTILS_ICON_BAN = 'ban';
export const UTILS_ICON_SLASH = 'slash';
export const UTILS_ICON_SHARE = 'share';

import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { List, SystemEntity } from '@wdx/clmi/api-models';
import {
    MenuItemAction,
    NavItem,
    TranslationsService,
} from '@wdx/shared/utils';

import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { ENTITY_ROUTE_MAP } from '@wdx/shared/utils';
import { CLIENT_MEMBERS_LIST } from '../../../../constants/client.constants';
import { ENTITY_ICON_MAP } from '../../../../constants/entity-icons.constants';
import {
    MEETING_MENU_ITEM,
    PHONECALL_MENU_ITEM,
    REMOVE_FROM_LIST_MENU_ITEM,
} from '../../../../constants/menu.constants';
import { ListFacade } from '../../../../features/lists/shared/services/list.facade';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { InfoCardData } from '../../../../models/info-card-data.model';
import { EntityRoutePipe } from '../../../../pipes/entity-route.pipe';
import { ListTypePipe } from '../../../../pipes/list-type.pipe';
import * as rootReducer from '../../../../state/_setup/reducers';
import { RouteDataService } from '../../../services';
import { EntityOperationsService } from '../../../services/entity-operations';
import { AccessVisibilityService } from '../../access-visibility/shared/services/access-visibility.service';
import {
    FilterQueryFacadeService,
    FilterQueryService,
} from '../../form-filters';
import { FilterTableComponentClass } from '../filter-table-component.class';
import * as listsActions from './../../../../state/lists/lists.actions';
import { LISTS_ACTION_BTN, LISTS_TABLE_HEADERS } from './lists.static';

@Component({
    selector: 'clmi-filter-table-lists',
    templateUrl: '../filter-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableListsComponent
    extends FilterTableComponentClass
    implements AfterViewInit, OnInit
{
    @Input() entityType: SystemEntity;
    @Input() entityId: string;
    @Input() partyId: string;

    @Output() menuItemSelected = new EventEmitter<NavItem>();
    actionButtons: ActionButton[] = LISTS_ACTION_BTN;

    @ViewChild('infoCardCell', { read: TemplateRef })
    infoCardCell: TemplateRef<any>;

    @ViewChild('actionButtonCell', { read: TemplateRef })
    actionButtonCell: TemplateRef<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected accessVisibilityService: AccessVisibilityService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,

        private actionSubject$: ActionsSubject,
        private listFacade: ListFacade
    ) {
        super(
            router,
            route,
            location,
            accessVisibilityService,
            store$,
            filterQueryService,
            filterQueryFacadeService,
            entityOperationsService,
            cd,
            routeDataService,
            translationsService
        );

        this.headers = LISTS_TABLE_HEADERS;
        this.table.headers = this.headers;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.actionSubject$
            .pipe(
                ofType(listsActions.deleteEntityFromListSuccess),
                takeUntil(this.destroyed$)
            )
            .subscribe((_) => {
                this.filterQueryFacadeService.resetResultsGetRecords(
                    this.filterQueryService.viewType,
                    {
                        expressions: [
                            {
                                ...CLIENT_MEMBERS_LIST,
                                values: [
                                    {
                                        id: this.entityId,
                                        lookupType: this.entityType,
                                    },
                                ],
                            },
                        ],
                    }
                );
            });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(
            this.filterQueryService.queryType,
            this.getListAsTableRow,
            this.infoCardCell,
            this.actionButtonCell
        );
    }

    resetTable(): void {
        super.resetTable(LISTS_TABLE_HEADERS);
    }

    onActionButtonMenuItemSelected(event): void {
        this.menuItemSelected.emit(event);

        if (MenuItemAction.RemoveFromList) {
            this.listFacade.deleteEntityFromList(
                event.data.list.id,
                this.entityType,
                this.entityId
            );
        }
    }

    getListAsTableRow = (list: List, infoCardCell, actionButtonCell) => {
        return {
            routerLink: new EntityRoutePipe().transform(
                SystemEntity.List,
                list.id
            ),
            values: [
                {
                    templateRef: infoCardCell,
                    data: {
                        primaryInfo: list.name,
                        icon: ENTITY_ICON_MAP[SystemEntity.List],
                        size: 'md',
                        avatarIconDivider: true,
                        routerLink: [
                            ...ENTITY_ROUTE_MAP[SystemEntity.List],
                            list.id,
                        ],
                    } as InfoCardData,
                },
                {
                    templateRef: infoCardCell,
                    data: {
                        primaryInfo: list.owner.name,
                        avatars: [
                            new PartyToAvatarPipe().transform(list.owner),
                        ],
                        size: 'sm',
                        routerLink: [],
                    } as InfoCardData,
                },
                {
                    value: new ListTypePipe().transform(list.type),
                },
                {
                    value: `${list.numberOfMembers || 0}`,
                },
                {
                    value: list.description,
                },
                {
                    value: list.status,
                },
                {
                    templateRef: actionButtonCell,
                    data: {
                        hideFavourite: true,
                        contextMenu: {
                            mode: ActionButtonMode.DropdownButtonMenu,
                            dropdownMenu: [
                                new PHONECALL_MENU_ITEM(this.partyId, {
                                    fileUnderId: this.entityId,
                                }).menuItem,
                                new MEETING_MENU_ITEM(this.partyId, {
                                    fileUnderId: this.entityId,
                                }).menuItem,
                                {
                                    ...REMOVE_FROM_LIST_MENU_ITEM,
                                    data: { list: { ...list } },
                                },
                            ],
                        } as ActionButton,
                    },
                },
            ],
        };
    };
}

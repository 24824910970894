import { createSelector } from '@ngrx/store';
import * as answersReducer from './answers.reducer';

export const getState = (state: {
    answers: answersReducer.State;
}): answersReducer.State => state.answers;

export const getAnswerSets = createSelector(
    getState,
    (state: answersReducer.State) => state.answerSet?.list
);

export const getAnswerSetsIsLoading = createSelector(
    getState,
    (state: answersReducer.State) => state.answerSet?.isLoadingList
);

export const getFilteredAnswerSets = createSelector(
    getState,
    (state: answersReducer.State) => state.answerSet?.filteredList
);

export const getAnswers = createSelector(
    getState,
    (state: answersReducer.State, props: { code: string }) =>
        state.answers && state.answers[props.code]
            ? state.answers[props.code].list
            : null
);

export const getAnswersIsLoading = createSelector(
    getState,
    (state: answersReducer.State, props: { code: string }) =>
        state.answers && state.answers[props.code]
            ? state.answers[props.code].isLoadingList
            : null
);

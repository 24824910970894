import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { Comment } from '@wdx/clmi/api-models';
import {
    URL_CASES,
    URL_CASES_WORK_SUB_ITEM,
    WdxDateFormat,
} from '@wdx/shared/utils';
import { OrganismAvatarInfoModalComponent } from '../../../../components/organisms/organism-avatar-info-modal/organism-avatar-info-modal.component';
import {
    ICON_CHECK_CIRCLE,
    ICON_DANGER,
} from '../../../../constants/icons.constants';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { CommentMentionedFragment } from '../comments.model';
import { TriggerChar } from '../shared/constants/mention.constant';
import { CommentsFacade } from '../shared/services/comments.facade';
import { Avatar } from '@wdx/clmi/utils/models';

const TILDE = '~';
@Component({
    selector: 'clmi-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnInit {
    public commentsFacade = inject(CommentsFacade);

    @Input() comment: Comment;
    @Input() appMentionClickCallback?: (appId: string) => void;
    @Input() resolveIssueId: string;
    @Input() readonly = false;
    @Input() reply = false;
    @Output() resolveIssueChecked = new EventEmitter<{
        checked: boolean;
        commentId: string;
    }>();

    commentFragments: CommentMentionedFragment[];
    public resolvingIssue = false;

    readonly COMMENT = 'comment';
    readonly MENTION = 'mention';
    readonly ICON_ISSUE = ICON_DANGER.icon;
    readonly ICON_RESOLVED = ICON_CHECK_CIRCLE.icon;
    readonly WdxDateFormat = WdxDateFormat;

    @ViewChild('commentContainer', { read: ElementRef })
    commentContainer: ElementRef;

    constructor(private modalManagementService: ModalManagementService) {}

    ngOnInit(): void {
        this.commentFragments = this.getCommentFragments(this.comment);
    }

    getCommentFragments(comment: Comment): CommentMentionedFragment[] {
        const COMMENT = { ...comment };

        COMMENT.content = COMMENT?.content?.replace(/##/g, `#${TILDE}`);

        return (
            COMMENT?.content &&
            // eslint-disable-next-line no-useless-escape
            COMMENT.content.split(/\#(.*?)\#/gi).map((fragment) => {
                if (fragment.startsWith(TriggerChar.At)) {
                    const mentioned = fragment.split(':');
                    return {
                        text: mentioned[0],
                        partyType: mentioned[1],
                        partyId: mentioned[2],
                    };
                }

                if (fragment.startsWith(TILDE)) {
                    const mentioned = fragment.split(':');
                    const text = mentioned[0].replace(TILDE, TriggerChar.Hash);
                    if (this.appMentionClickCallback) {
                        return {
                            text,
                            callback: () =>
                                this.appMentionClickCallback(mentioned?.[3]),
                        };
                    }
                    return {
                        text,
                        link: [
                            ...URL_CASES,
                            mentioned[1],
                            ...URL_CASES_WORK_SUB_ITEM,
                            mentioned[2],
                            'app',
                            mentioned[3],
                        ],
                    };
                }

                return { text: fragment };
            })
        );
    }

    openAvatarModal(avatar: Avatar): void {
        const avatarModal = this.modalManagementService.openModalWithComponent(
            OrganismAvatarInfoModalComponent
        );
        avatarModal.componentInstance.avatar = avatar;
    }

    resolveIssue(checked: boolean) {
        this.resolvingIssue = checked;
        this.resolveIssueChecked.emit({ checked, commentId: this.comment.id });
    }
}

import { Injectable } from '@angular/core';
import {
    FieldDefinition,
    FormDefinition,
    FormElementLayoutDefinition,
    FormLayout,
} from '@wdx/shared/utils';

import { FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormLayoutAndDefinition } from '../../../../models';

const ADDRESS_CONTROL_ADDRESS_TYPE = 'addressType';

@Injectable()
export class AddressTypeService {
    public addressTypeSchema: ReactiveFormLayoutAndDefinition;

    /**
     * This update the definition. The reason this updates the definition is because we are using the options from the parent form.
     * The options from the parent form updates the ManualAddressForm addressType options. This because there is only one manualAddressForm for the tenant but addressType needs to be different for different forms.
     * Because the parent form doesn't speak to the manualAddressForm we are using the parent Address field type options to override the manualAddressForm addressType options.
     * If there is only 1 option then the control needs to be disabled. If it is a new form with no data then then the control needs to be updated with the options value and disabled
     *
     * @param {FormDefinition} definition
     * @param {ReactiveFormLayoutAndDefinition} formElement
     * @returns {FormDefinition}
     */
    public overrideDefinition(
        definition: FormDefinition,
        formElement: ReactiveFormLayoutAndDefinition
    ): FormDefinition {
        const DEFINITION = { ...definition };
        const OPTIONS = formElement.options;

        const SCHEMA: FieldDefinition[] = [];
        const sectionLayoutDefinitions: FormLayout = {};

        DEFINITION.schema?.forEach((fieldDefinition) => {
            if (fieldDefinition.name === ADDRESS_CONTROL_ADDRESS_TYPE) {
                this.addressTypeSchema = {
                    ...fieldDefinition,
                    options: OPTIONS,
                };
                SCHEMA.push(this.addressTypeSchema);
            } else {
                SCHEMA.push(fieldDefinition);
            }
        });

        const LAYOUT = {
            ...DEFINITION.layout,
            sectionLayoutDefinitions:
                DEFINITION.layout?.sectionLayoutDefinitions?.map(
                    (sectionLayoutDefinition) => {
                        const ELEMENT_LAYOUT_DEFINITION = (
                            sectionLayoutDefinition.elementLayoutDefinitions as FormElementLayoutDefinition[]
                        ).map((formElementLayoutDefinition) => {
                            const FORM_ELEMENT_LAYOUT_DEFINITION = {
                                ...formElementLayoutDefinition,
                            };

                            if (
                                formElementLayoutDefinition.name ===
                                ADDRESS_CONTROL_ADDRESS_TYPE
                            ) {
                                this.addressTypeSchema = {
                                    ...this.addressTypeSchema,
                                    ...formElementLayoutDefinition,
                                };
                                if (OPTIONS?.length === 1) {
                                    FORM_ELEMENT_LAYOUT_DEFINITION.isDisabled =
                                        true;
                                    FORM_ELEMENT_LAYOUT_DEFINITION.isHidden =
                                        true;
                                }
                            }

                            return FORM_ELEMENT_LAYOUT_DEFINITION;
                        });

                        return {
                            ...sectionLayoutDefinitions,
                            elementLayoutDefinitions: ELEMENT_LAYOUT_DEFINITION,
                        };
                    }
                ),
        };

        return { ...DEFINITION, schema: SCHEMA, layout: LAYOUT };
    }

    /**
     * This will update the addressType if it only has 1 option
     *
     * @param {FormGroup} formControls - This this the form controls form ManualAddressForm
     * @param {boolean} hasFormData - Has any visible form controls have data
     */
    public setAddressTypeValue(
        formControls: FormGroup,
        hasFormData: boolean
    ): void {
        const ADDRESS_TYPE_CONTROL = formControls.get(
            ADDRESS_CONTROL_ADDRESS_TYPE
        ) as FormControl;
        if (
            !ADDRESS_TYPE_CONTROL?.value &&
            this.addressTypeSchema?.options?.length === 1 &&
            hasFormData
        ) {
            this.formControlData(
                ADDRESS_TYPE_CONTROL,
                this.addressTypeSchema.options[0].value
            );
        }

        if (
            ADDRESS_TYPE_CONTROL?.value &&
            this.addressTypeSchema?.options?.length === 1 &&
            !hasFormData
        ) {
            this.formControlData(ADDRESS_TYPE_CONTROL, null);
        }
    }

    private formControlData(
        ADDRESS_TYPE_CONTROL: FormControl,
        val: string | null
    ): void {
        ADDRESS_TYPE_CONTROL.setValue(val, {
            emitEvent: false,
            onlySelf: false,
        });
        ADDRESS_TYPE_CONTROL.updateValueAndValidity();
    }
}

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsyncSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    DynamicFormsFacade,
    dynamicFormsSelectors,
} from '../../+state/dynamic-forms';
import * as dynamicFormsReducer from '../../+state/dynamic-forms/dynamic-forms.reducer';
import { FormDefinition } from '@wdx/shared/utils';

@Injectable()
export class FormPresentationService {
    formDefinition$ = new AsyncSubject<FormDefinition>();
    formDefinitionIsLoading$: Observable<boolean>;
    formDefinitionHasError$: Observable<boolean>;
    private dynamicFormsFacade = inject(DynamicFormsFacade);
    private store$ = inject(
        Store<{
            [dynamicFormsReducer.FEATURE_KEY]: dynamicFormsReducer.State;
        }>
    );

    queryFormDefinition(formId: string): void {
        this.dynamicFormsFacade.getFormDefinition$(formId);
        this.getFormDefinition(formId);
        this.getFormDefinitionIsLoading(formId);
        this.getFormDefinitionHasError(formId);
    }

    getFormDefinition(formId: string): void {
        this.dynamicFormsFacade.getFormDefinition$(formId).subscribe((res) => {
            this.formDefinition$.next(res as FormDefinition);
            this.formDefinition$.complete();
        });
    }

    getFormDefinitionIsLoading(formId: string): void {
        this.formDefinitionIsLoading$ = this.store$
            .select(dynamicFormsSelectors.getIsLoadingFormDefinition, {
                id: formId,
            })
            .pipe(map(Boolean));
    }

    getFormDefinitionHasError(formId: string): void {
        this.formDefinitionHasError$ = this.store$
            .select(dynamicFormsSelectors.getHasLoadingFormDefinitionError, {
                id: formId,
            })
            .pipe(map(Boolean));
    }
}

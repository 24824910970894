import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormDataResult, FormLock, IconStyle } from '@wdx/shared/utils';
import { FormLockService } from '../../../services';

@Component({
    selector: 'wdx-ff-lock-summary',
    templateUrl: './form-lock-summary.component.html',
})
export class FormLockSummaryComponent implements OnInit {
    @Input() formData: FormDataResult;

    @HostBinding('class') class = 'd-block w-100';

    shouldOverride: boolean;

    readonly ICON_STYLE = IconStyle;

    constructor(private formLockService: FormLockService) {}

    ngOnInit(): void {
        this.shouldOverride = !!this.formLockService.shouldOverrideFormLock(
            this.formData.lock as FormLock
        );
    }
}

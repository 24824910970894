import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { FORM_ID_ENTITY_MAP, Severity, TitleCasePipe } from '@wdx/shared/utils';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TOAST_CONSTANTS_CHAT } from '../constants/toast.constants';
import { EntityRoutePipe } from '../pipes/entity-route.pipe';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigService } from '../services/config.service';
import { USER_ME_403_MESSAGE } from '../shared/features/message/constants/message.constants';
import { MessageDetails } from '../shared/features/message/models/message.model';
import { MessageFacade } from '../shared/features/message/shared/services/message.facade';

@Injectable()
export class ToastInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    if (event.status === 201) {
                        const location = event.headers.get('location');

                        if (!location) {
                            return;
                        }

                        const locationPaths = location
                            .substr(
                                this.config.getConfiguration().API_BASE.length +
                                    1
                            )
                            .split('/');

                        const formDataOrEntityType = locationPaths[0];
                        const formId = locationPaths[1];
                        const entityIdOrName =
                            locationPaths[locationPaths.length - 1];

                        if (
                            [TOAST_CONSTANTS_CHAT].includes(entityIdOrName) ||
                            formDataOrEntityType.toLowerCase() === 'dashboard'
                        ) {
                            return;
                        }

                        const entityType = this.getEntityType(
                            formDataOrEntityType,
                            formId
                        );

                        const entityRouterLink = this.getEntityRouterLink(
                            entityType,
                            entityIdOrName
                        );

                        const toastBodyContent = `Record successfully created${
                            entityRouterLink.length ? '. Click to view' : ''
                        }`;

                        const entitiesWithoutPages = [
                            SystemEntity.Answer,
                            SystemEntity.Tag,
                            SystemEntity.DocumentRequirement,
                        ] as (SystemEntity | UserActionEntity)[];
                        this.toastService.show({
                            body: [toastBodyContent],
                            severity: Severity.Success,
                            ...(!entitiesWithoutPages.includes(entityType) &&
                                entityIdOrName &&
                                entityType && {
                                    bodyRouterLink: entityRouterLink,
                                }),
                        });
                    }

                    if (event.status.toString().match(/20\d*/g)) {
                        const url = event.url;
                        const urlSectors = url
                            .substr(
                                this.config.getConfiguration()?.API_BASE.length
                            )
                            .split('/');
                        let entity: string = urlSectors && urlSectors[1];

                        const ADD_DOCUMENT_TO_ENVELOPE =
                            req.method === 'PUT' &&
                            url.includes('envelope') &&
                            url.includes('document');

                        if (
                            entity &&
                            url &&
                            (url.indexOf('/status/complete') !== -1 ||
                                url.indexOf('/status/Enabled') !== -1 ||
                                url.indexOf('/status/Available') !== -1 ||
                                url.indexOf('/query?entityName') !== -1 ||
                                ADD_DOCUMENT_TO_ENVELOPE)
                        ) {
                            let actionTitle =
                                url.indexOf('/status/complete') !== -1
                                    ? 'completed'
                                    : url.indexOf('/status/Enabled') !== -1
                                    ? 'role enabled'
                                    : url.indexOf('/status/Available') !== -1
                                    ? 'role available'
                                    : url.indexOf('query?entityName') !== -1
                                    ? 'updated'
                                    : '';

                            if (ADD_DOCUMENT_TO_ENVELOPE) {
                                entity = 'document';
                                actionTitle = 'added to envelope';
                            }

                            this.toastService.show({
                                body: [
                                    `${new TitleCasePipe().transform(
                                        entity
                                    )} ${actionTitle} successfully`,
                                ],
                                severity: Severity.Success,
                                bodyRouterLink:
                                    actionTitle === 'updated'
                                        ? this.getEntityRouterLink(
                                              new TitleCasePipe().transform(
                                                  entity
                                              ) as SystemEntity,
                                              urlSectors[2]
                                          )
                                        : null,
                            });
                        }
                    }
                }
            }),
            catchError((event) => {
                if (event.status === 422) {
                    this.toastService.show({
                        body: [event.detail || event.error.detail],
                        severity: Severity.Danger,
                    });
                }

                if (event.status === 403 && event.url.includes('api/user/me')) {
                    const authCode = event.headers.get('x-auth-code');

                    const message: MessageDetails = {
                        message: USER_ME_403_MESSAGE,
                        ...(authCode && { subMessage: `code: ${authCode}` }),
                    };

                    this.messageFacade.addErrorMessage(message);
                }

                return throwError(event.error);
            })
        );
    }

    getEntityType(
        formDataOrEntity: string,
        formId: string
    ): SystemEntity | UserActionEntity {
        const entityForFormId: SystemEntity = FORM_ID_ENTITY_MAP[formId];
        if (entityForFormId) {
            return entityForFormId;
        }
        return (
            SystemEntity[formDataOrEntity] || UserActionEntity[formDataOrEntity]
        );
    }

    getEntityRouterLink(
        entityType: SystemEntity | UserActionEntity,
        entityId: string
    ) {
        if (!entityId || entityType === SystemEntity.Opportunity) {
            return new EntityRoutePipe().transform(entityType);
        }
        return new EntityRoutePipe().transform(entityType, entityId);
    }

    constructor(
        private config: ConfigService,
        private toastService: WdxToastService,
        private authenticationService: AuthenticationService,
        private messageFacade: MessageFacade
    ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { PushModule } from '@ngrx/component';
import { OrganismActivityTemplateSearchComponentDumb } from './components/organism-activity-template-search-dumb/organism-activity-template-search-dumb.component';
import { OrganismActivityTemplateSearchComponent } from './containers/organism-activity-template-search/organism-activity-template-search.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeInfoCardSearchModule,
        PipesModule,
        PushModule,
    ],
    declarations: [
        OrganismActivityTemplateSearchComponent,
        OrganismActivityTemplateSearchComponentDumb,
    ],
    exports: [
        OrganismActivityTemplateSearchComponent,
        OrganismActivityTemplateSearchComponentDumb,
    ],
})
export class OrganismActivityTemplateSearchModule {}

import { createAction, props } from '@ngrx/store';

export const getImportEntities = createAction('[Import] Get Import Entities');
export const getImportEntitiesSuccess = createAction(
    '[Import] Get Import Entities Success',
    props<{
        imports: string[];
    }>()
);
export const getImportEntitiesFailure = createAction(
    '[Import] Get Import Entities Failure',
    props<{
        error: Error;
    }>()
);

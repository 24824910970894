export enum FormFrameworkEventType {
    Dirty,
    SaveStart,
    SaveFailed,
    SaveSuccess,
    FormLoaded,
}

export interface FormFrameworkEvent {
    type: FormFrameworkEventType;
    action?: { dirty: boolean };
}

export class FormFrameworkDirtyEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.Dirty;
    action: { dirty: boolean };

    constructor(dirty: boolean) {
        this.action = { dirty };
    }
}

export class FormFrameworkSaveStartEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveStart;
}

export class FormFrameworkSaveFailedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveFailed;
}

export class FormFrameworkSaveSuccessEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveSuccess;
}

export class FormFrameworkLoadedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.FormLoaded;
}

import { Location } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { RoutableSystemEntity } from '../models/system-entity-action-props.model';
import { EntityRoutePipe } from '../pipes/entity-route.pipe';

@Pipe({ name: 'entityRouteOpenNewTab' })
export class EntityRouteOpenNewTabPipe implements PipeTransform {
    transform(
        entityType: SystemEntity | UserActionEntity,
        router: Router,
        route: ActivatedRoute,
        location: Location,
        entityId?: string | number,
        regardingId?: string | number,
        queryParams?: KeyValueObject,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number
    ): void {
        const url = router.serializeUrl(
            router.createUrlTree(
                new EntityRoutePipe().transform(
                    SystemEntity[entityType] || UserActionEntity[entityType],
                    entityId,
                    regardingId,
                    subEntityType,
                    subEntityId
                ),
                {
                    relativeTo: route,
                    queryParams,
                }
            )
        );

        url && window.open(location.prepareExternalUrl(url));
    }
}

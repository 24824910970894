import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { ImportStatusType } from '@wdx/clmi/api-models';
import { ImportApiService } from '@wdx/clmi/api-services/services';
import { tap } from 'rxjs/operators';
import { IMPORT_FORM_MODAL } from './shared/constants';

import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity, URL_IMPORTS } from '@wdx/shared/utils';
import { ModalManagementService } from '../../../services/modal-management.service';

@Component({
    selector: 'clmi-import-file-uploader',
    templateUrl: './import-file-uploader.component.html',
})
export class ImportFileUploaderComponent implements AfterViewInit, OnDestroy {
    @Input() entityType;

    files: File[] = [];
    isLoadingData = false;

    readonly IMPORT_FORM_MODAL = IMPORT_FORM_MODAL;

    @Output() clmiAfterViewInit = new EventEmitter();
    @Output() clmiOnClosed = new EventEmitter();

    constructor(
        private importApiService: ImportApiService,
        private modalManagementService: ModalManagementService,
        private toastService: WdxToastService
    ) {}

    ngAfterViewInit(): void {
        this.clmiAfterViewInit.emit();
    }

    ngOnDestroy(): void {
        this.clearFiles();
    }

    onClose(): void {
        this.clearFiles();
        this.closeModal();
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    onClick(): void {
        this.importApiService
            .importFileData$(this.entityType, this.files[0])
            .pipe(tap(() => (this.isLoadingData = true)))

            .subscribe(
                (_) => undefined,
                () => {
                    this.clearFileUpload(
                        Severity.Danger,
                        `Importing of file was unsuccessful`
                    );
                },
                () => {
                    this.clearFileUpload(
                        Severity.Success,
                        `Import background job successfully generated`
                    );
                }
            );
    }

    clearFileUpload(severity: Severity, message: string): void {
        this.isLoadingData = false;
        this.clearFiles();
        this.closeModal();
        this.toastService.show({
            body: [message],
            severity,
            closeOthers: true,
            bodyRouterLink: URL_IMPORTS,
        });
    }

    clearFiles(): void {
        this.files = [];
    }

    closeModal(): void {
        this.clmiOnClosed.emit();
        this.modalManagementService.closeActiveModal();
    }

    getProgressType(fileUploadStatus: ImportStatusType) {
        if (fileUploadStatus) {
            return {
                [ImportStatusType.Processing]: 'success',
                [ImportStatusType.Created]: 'success',
                [ImportStatusType.Completed]: 'success',
                [ImportStatusType.Errored]: 'danger',
            }[fileUploadStatus];
        }
        return null;
    }
}

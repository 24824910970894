import { Action, createReducer, on } from '@ngrx/store';
import { Answer, AnswerSet } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as answersActions from './answers.actions';

export interface State {
    answerSet?: CrudState<AnswerSet>;
    answers?: CrudStateObject<Answer>;
}

export const initialState: State = {
    answerSet: null,
    answers: null,
};

const reducerSetup = createReducer(
    initialState,

    on(
        answersActions.getAnswerSets,
        (state): State => ({
            ...state,
            answerSet: {
                isLoadingList: true,
            },
        })
    ),

    on(
        answersActions.getAnswerSetsSuccess,
        (state, props): State => ({
            ...state,
            answerSet: {
                list: props.answerSetList,
                filteredList: props.answerSetList,
                isLoadingList: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        answersActions.getAnswerSetsFailure,
        (state): State => ({
            ...state,
            answerSet: {
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        answersActions.filterAnswerSets,
        (state, props): State => ({
            ...state,
            answerSet: {
                ...state.answerSet,
                filteredList:
                    props.searchText.length < 2
                        ? state.answerSet.list
                        : state.answerSet.list
                              .filter(
                                  (answerSet) =>
                                      answerSet.name
                                          .toLowerCase()
                                          .indexOf(
                                              props.searchText.toLowerCase()
                                          ) > -1
                              )
                              .slice(0, 10),
            },
        })
    ),

    on(
        answersActions.getAnswers,
        (state, props): State => ({
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        answersActions.getAnswersSuccess,
        (state, props): State => ({
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    list: props.answers,
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        answersActions.getAnswersFailure,
        (state, props): State => ({
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        answersActions.updateAnswerSuccess,
        (state, props): State => ({
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    list: (state.answers[props.code].list as Answer[]).map(
                        (answer) => ({
                            ...answer,
                            ...(answer.id === props.id && props.answer),
                        })
                    ),
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        answersActions.createAnswerSuccess,
        (state, props): State => ({
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    list: [...state.answers[props.code].list, props.answer],
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(answersActions.reorderAnswers, (state, props): State => {
        return {
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    list: state.answers[props.code].list,
                    isLoadingList: true,
                },
            },
        };
    }),

    on(answersActions.reorderAnswersSuccess, (state, props): State => {
        return {
            ...state,
            answers: {
                ...state.answers,
                [props.code]: {
                    list: props.answers,
                    isLoadingList: false,
                },
            },
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}

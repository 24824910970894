import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '@wdx/clmi/api-models';
import {
    LocaleFacadeService,
    SystemApiService,
    UserApiService,
    userActions,
    userSelectors,
} from '@wdx/clmi/api-services/services';
import {
    FeaturesService,
    TenantsService,
    TranslationsService,
} from '@wdx/shared/utils';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';
import { SystemSettingsService } from '../../../shared/services';
import * as rootReducer from '../../../state/_setup/reducers';
import * as globalActions from '../../../state/global/global.actions';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ThemeService } from '../../services/theme/theme.service';

@Injectable({
    providedIn: 'root',
})

/**
 * Guard that is run once on the top level App component
 */
export class AppGuard implements CanActivate {
    private router = inject(Router);
    private store$ = inject(Store<rootReducer.State>);
    private authenticationService = inject(AuthenticationService);
    private configService = inject(ConfigService);
    private systemApiService = inject(SystemApiService);
    private userApiService = inject(UserApiService);
    private tenantsService = inject(TenantsService);
    private themeService = inject(ThemeService);
    private systemSettingsService = inject(SystemSettingsService);
    private featuresService = inject(FeaturesService);
    private translationsService = inject(TranslationsService);
    private localeFacadeService = inject(LocaleFacadeService);
    private navigationService = inject(NavigationService);

    canActivate(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                /**
                 * Set isLoggedIn status on AuthenticationService
                 */
                this.authenticationService.loggedIn = isAuthenticated;

                /**
                 * If user not authenticated redirect to welcome page and restrict access to app
                 */
                if (!isAuthenticated) {
                    this.router.navigate(['/'], {
                        queryParams: {
                            ['return_to']: state.url,
                        },
                    });
                    this.tenantsService.clearChangeTenantCode();
                    return of(false);
                }

                /**
                 * Handles redirectTo url in the background (if applicable)
                 * Get appstate will only be subscribed to on a successfull login callback
                 */
                this.authenticationService
                    .getAppState$()
                    .pipe(
                        take(1),
                        filter((appState) => Boolean(appState.returnToUrl))
                    )
                    .subscribe((appState) =>
                        this.authenticationService.redirectToUrl(
                            appState.returnToUrl
                        )
                    );

                /**
                 * If user is authenticated dispatch getMe(), perform route setup and return to app
                 */
                this.store$.dispatch(userActions.getMeAction());

                return this.store$.select(userSelectors.getMeSelector).pipe(
                    filter((res) => Boolean(res)),
                    first(),

                    // set tenant code
                    tap((user: User) => {
                        this.tenantsService.tenantCode = user.tenant;
                    }),

                    //  loads eager apis (those required to start the application)
                    switchMap(() =>
                        forkJoin({
                            features: this.systemApiService.getFeatures(),
                            sitemap: this.systemApiService.getSiteMap(),
                            settings: this.systemApiService.getSystemSettings(),
                            tenants: this.userApiService.getTenants(),
                            theme: this.systemApiService.getTheme(),
                            translations:
                                this.systemApiService.getTranslations(),
                            locales: this.localeFacadeService.loadLocales$(),
                        })
                    ),
                    tap((res) => {
                        // set the eager responses on relevant core services
                        this.systemSettingsService.set(res.settings);
                        this.featuresService.set(res.features);
                        this.themeService.set(res.theme);
                        this.tenantsService.set(res.tenants);
                        this.translationsService.set(res.translations);
                        this.navigationService.setSiteMap(res.sitemap);

                        // initialise lazy global actions
                        this.store$.dispatch(
                            globalActions.initialiseApp({ isAuthenticated })
                        );
                    }),

                    // Return to app
                    map(() => true)
                );
            })
        );
    }

    get isAuthenticated$() {
        return this.configService?.config?.TestMode
            ? of(true)
            : this.authenticationService.auth0Service.isAuthenticated$;
    }
}

import { createSelector } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';
import { NgRxService } from '../../services/ngrx.service';
import * as workItemsReducer from './work-items.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    workItems: workItemsReducer.State;
}): workItemsReducer.State => state.workItems;

export const getWorkItemsForProcessIsLoading = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Process, props)]
            ?.isLoadingList
);
export const getWorkItemsForProcessHasError = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Process, props)]
            ?.hasLoadingListError
);
export const getWorkItemsForProcess = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Process, props)]?.list
);

export const getWorkItemsForCaseIsLoading = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Case, props)]
            ?.isLoadingList
);
export const getWorkItemsForCaseHasError = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Case, props)]
            ?.hasLoadingListError
);
export const getWorkItemsForCase = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[NgRxService.chunkId(SystemEntity.Case, props)]?.list
);

export const getWorkItemIsLoadingSingle = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[props.id]?.isLoadingSingle
);
export const getWorkItemHasLoadingSingleError = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[props.id]?.hasLoadingSingleError
);
export const getWorkItem = createSelector(
    getState,
    (state: workItemsReducer.State, props: any) =>
        state.workItems[props.id]?.single
);

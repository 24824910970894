import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushModule } from '@ngrx/component';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomAvatarModule } from '../../../components/atoms/atom-avatar/atom-avatar.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeButtonGroupModule } from '../../../components/molecules/molecule-button-group/molecule-button-group.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeAvatarInfoDumbComponent } from './components/molecule-avatar-info-dumb/molecule-avatar-info-dumb.component';
import { MoleculeAvatarInfoComponent } from './containers/molecule-avatar-info/molecule-avatar-info.component';
import { TitleCasePipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomAvatarModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeButtonGroupModule,
        PipesModule,
        PushModule,
        RouterModule,
        WdxBadgeModule,
        TitleCasePipe,
    ],
    declarations: [
        MoleculeAvatarInfoComponent,
        MoleculeAvatarInfoDumbComponent,
    ],
    exports: [MoleculeAvatarInfoComponent],
})
export class MoleculeAvatarInfoModule {}

import { Action, createReducer, on } from '@ngrx/store';
import { FilterDefinition } from '@wdx/clmi/api-models';
import { Paging } from '@wdx/shared/utils';
import * as filterActions from '../actions';

export interface FilterState {
    [key: string]: {
        definition?: {
            data: FilterDefinition;
            isLoading: boolean;
            hasLoadingError: boolean;
        };
        firstResults: any[];
        firstPaging: Paging;
        results: any[];
        paging: Paging;
        isLoading: boolean;
        hasLoadingError: boolean;
    };
}

export const initialState: FilterState = {};

const reducerSetup = createReducer(
    initialState,

    on(
        filterActions.getFilterResults,
        filterActions.getFilterResultsMergeMap,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                firstResults: null,
                firstPaging: null,
                results: null,
                paging: null,
                isLoading: true,
                hasLoadingError: false,
                definition: state[props.selectType]?.definition || null,
            },
        })
    ),

    on(filterActions.getFilterResultsSuccess, (state, props): FilterState => {
        const RESULTS =
            // eslint-disable-next-line no-unsafe-optional-chaining
            props.payload?.results === null ? [] : [...props.payload?.results];

        return {
            ...state,
            [props.selectType]: {
                ...state[props.selectType],
                firstResults: RESULTS,
                firstPaging: { ...props.payload?.paging },
                results: RESULTS,
                paging: { ...props.payload?.paging },
                isLoading: false,
                definition: state[props.selectType]?.definition || null,
            },
        };
    }),

    on(
        filterActions.getFilterResultsFailure,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                ...state[props.selectType],
                isLoading: false,
                hasLoadingError: true,
            },
        })
    ),

    on(
        filterActions.updateFilterResults,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                ...state[props.selectType],
                isLoading: true,
            },
        })
    ),

    on(
        filterActions.updateFilterResultsSuccess,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                ...state[props.selectType],
                results: [...props.payload.results],
                paging: { ...props.payload.paging },
                isLoading: false,
                hasLoadingError: false,
            },
        })
    ),

    on(
        filterActions.updateFilterResultsFailure,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                ...state[props.selectType],
                hasLoadingError: true,
            },
        })
    ),

    on(
        filterActions.resetFilterResults,
        (state, props): FilterState => ({
            ...state,
            [props.selectType]: {
                firstResults: state[props.selectType]?.firstResults || null,
                firstPaging: state[props.selectType]?.firstPaging || null,
                results: null,
                paging: null,
                isLoading: false,
                hasLoadingError: false,
                definition: state[props.selectType]?.definition || null,
            },
        })
    ),

    on(filterActions.updateAnyFilterProperty, (state, props): FilterState => {
        const INDEX = state[props.filterType]?.firstResults?.findIndex(
            (view) => view.id === props.id
        );

        if (INDEX !== -1 && typeof INDEX === 'number') {
            return {
                ...state,
                [props.filterType]: {
                    ...state[props.filterType],
                    firstResults: [
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state[props.filterType]?.firstResults.slice(
                            0,
                            INDEX
                        ),
                        {
                            ...state[props.filterType]?.firstResults[INDEX],
                            ...props.payload,
                        },
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...state[props.filterType]?.firstResults.slice(
                            INDEX + 1
                        ),
                    ],
                },
            };
        }

        return { ...state };
    }),

    on(
        filterActions.getFilterDefinitionSuccess,
        (state, props): FilterState => ({
            ...state,
            [props.queryType]: {
                ...state[props.queryType],
                definition: {
                    data: props?.payload,
                    isLoading: false,
                    hasLoadingError: false,
                },
            },
        })
    ),

    on(
        filterActions.getFilterDefinitionFailure,
        (state, props): FilterState => ({
            ...state,
            [props.queryType]: {
                ...state[props.queryType],
                definition: {
                    ...state[props.queryType]?.definition,
                    hasLoadingError: true,
                },
            },
        })
    )
);

export function reducer(state: FilterState | undefined, action: Action) {
    return reducerSetup(state, action);
}

<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage : formElement)
                : ''
        "
        [warningMessage]="warningText"
    >
        <div class="input-group" [formGroup]="telephoneFormGroup">
            <ng-select
                #countrySelect
                formControlName="country"
                bindLabel="name"
                bindValue="isoCode"
                [attr.data-cy]="'dropdown-telephone-' + formElement?.label"
                [clearable]="false"
                [readonly]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement?.pendingChange?.status)
                    )
                "
            >
                <ng-option
                    *ngFor="
                        let option of countries$ | ngrxPush;
                        let index = index
                    "
                    attr.data-cy="option-country"
                    [value]="option.isoCode"
                >
                    <span class="d-flex align-items-center">
                        <span
                            class="flag flag-{{
                                option?.isoCode | lowercase
                            }} me-1"
                        ></span>
                        <span class="hide-on-trigger ms-1">{{
                            option.name
                        }}</span>
                        <span class="text-muted mx-1">
                            {{ option.dialCode }}
                        </span>
                    </span>
                </ng-option>
            </ng-select>
            <input
                wdxInputText
                formControlName="number"
                type="tel"
                inputmode="tel"
                [mask]="mask"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.maxlength]="formElement.max || 35"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement?.pendingChange?.status)
                    )
                "
            />
            <ng-container *ngIf="options$ | ngrxPush as options">
                <ng-select
                    *ngIf="options.length > 1"
                    formControlName="type"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="Select"
                    data-cy="select-type"
                    [items]="options"
                    [searchable]="false"
                    [clearable]="false"
                    [readonly]="
                        isDisabled ||
                        PENDING_CHANGES_LOCK_FIELD.includes(
                            $any(formElement?.pendingChange?.status)
                        )
                    "
                >
                </ng-select>
                <div *ngIf="options.length === 1" class="input-group-text">
                    {{ options[0]?.label }}
                </div>
            </ng-container>
        </div>
    </wdx-form-field>
</ng-container>

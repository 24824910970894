import { SystemEntity } from '@wdx/clmi/api-models';

import { Severity } from '@wdx/shared/utils';

export interface NotificationAlert {
    label: string | number;
    severity: Severity;
}

export interface InterpolationFragment {
    type: InterpolationFragmentType;
    value: string;
    entityType?: SystemEntity;
    entityId?: string;
    subEntityType?: string;
    subEntityId?: string;
    fileIndex?: string;
    url?: string[];
}

export enum InterpolationFragmentType {
    Text = 'Text',
    Link = 'Link',
    FileDownload = 'FileDownload',
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'wdx-switch',
    templateUrl: './wdx-switch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxSwitchComponent implements OnInit {
    @Input() toggled?: boolean;
    @Input() disabled?: boolean;

    @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    instanceId?: string;

    ngOnInit() {
        this.instanceId = uuidv4();
    }

    toggleCheck() {
        this.toggle.emit(this.toggled);
    }

    toggleSwitch() {
        this.toggled = !this.toggled;
        this.toggle.emit(this.toggled);
    }
}

import { Component, HostBinding, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ReactiveFormLayoutAndDefinition } from '../../models';
import { FormFieldType } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-ff-controls-container',
    templateUrl: './form-controls-container.component.html',
})
export class FormControlsContainerComponent {
    @Input() entityId: string;
    @Input() formElement: ReactiveFormLayoutAndDefinition;
    @Input() formContext: any;

    @HostBinding('class') class = 'd-block position-relative mb-3';

    constructor(public controlContainer: ControlContainer) {}

    readonly FORM_FIELD_TYPE = FormFieldType;
}

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ImportEntitiesService } from '@wdx/clmi/api-services/services';
import { catchError, map, of, switchMap } from 'rxjs';
import * as importEntitiesActions from './import-entities.actions';

@Injectable()
export class ImportEntitiesEffects {
    private actions$ = inject(Actions);
    private importEntitiesService = inject(ImportEntitiesService);

    getImportEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(importEntitiesActions.getImportEntities),
            switchMap(() =>
                this.importEntitiesService.getImportEntities().pipe(
                    map((imports) =>
                        importEntitiesActions.getImportEntitiesSuccess({
                            imports,
                        })
                    ),
                    catchError((error) =>
                        of(
                            importEntitiesActions.getImportEntitiesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}

import { Location } from '@angular/common';
import { Optional, Pipe, PipeTransform } from '@angular/core';

import { SystemEntity } from '@wdx/clmi/api-models';

import {
    ADMIN_ENTITY_ROUTE_MAP,
    ENTITY_ROUTE_MAP,
    PipelineActionEntity,
    ROUTE_PREFIX_ADMINISTRATION,
} from '@wdx/shared/utils';
import { RoutableSystemEntity } from '../models/system-entity-action-props.model';

/**
 * Configures the correct route url fragment to be passed into the angular router as per a given entity type and id.
 *
 * Usage
 *
 * It is recommended where possible to use the pipe in a template
 *
 * ```
 * <a [routerLink]="SYSTEM_ENTITY.Case | entityRoute : entityId"
 * ```
 *
 * If using in a component then the Location should be provided where the target location could be admin.
 *
 * ```
 * new EntityRoutePipe(location).transform(SystemEntity.Case, 'abc');
 * ```
 *
 */

@Pipe({ name: 'entityRoute' })
export class EntityRoutePipe implements PipeTransform {
    constructor(@Optional() private location?: Location) {}

    transform(
        entityType: RoutableSystemEntity,
        entityId?: string | number,
        regardingId?: string | number,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number
    ): string[] {
        const ROUTE_FRAGMENT = this.getEntityType(entityType, entityId);

        if (!entityType) {
            return [];
        }

        if (!ROUTE_FRAGMENT) {
            return entityType !== SystemEntity.User ? [] : null;
        }

        return [
            ...(ROUTE_FRAGMENT || []),
            ...(regardingId ? [String(regardingId)] : []),
            ...(entityId ? [String(entityId)] : []),
            ...(subEntityType
                ? this.getEntityType(subEntityType, subEntityId)
                : []),
            ...(subEntityId ? [String(subEntityId)] : []),
        ];
    }

    /**
     * Returns the relevant route fragment
     * - If the entityType could be inside admin (e.g. user or team) returns the relevant route from ADMIN_ENTITY_ROUTE_MAP
     * - If the entityType is Opportunity pass in custom entity if a single item
     * - For all other types return standard item from ENTITY_ROUTE_MAP
     * @param entityType
     * @param entityId
     */
    getEntityType(
        entityType: RoutableSystemEntity,
        entityId?: string | number
    ): string[] {
        if (entityType in ADMIN_ENTITY_ROUTE_MAP && this.isAdminLocation()) {
            return ADMIN_ENTITY_ROUTE_MAP[entityType];
        }

        if (entityType === SystemEntity.Opportunity && entityId) {
            return ENTITY_ROUTE_MAP[PipelineActionEntity.OpportunityItem];
        }

        return ENTITY_ROUTE_MAP[entityType];
    }

    /**
     * Determines if the current location is inside systen admin.
     */
    isAdminLocation() {
        return this.location
            ?.path()
            .startsWith(ROUTE_PREFIX_ADMINISTRATION.join('/'));
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
    Activity,
    ActivityStatusType,
    ActivityType,
    Article,
    DocumentType,
    MenuItem,
    Party,
    ProcessOutcome,
    SystemEntity,
    WorkItem,
} from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    FeaturesService,
    OPPORTUNITY_FORM_ID,
    URL_CASES,
    URL_CASES_WORK_SUB_ITEM,
    URL_INBOX,
    URL_TASKS,
} from '@wdx/shared/utils';
import { FilterHandler } from '../../../../../classes/filter-handler.class';
import {
    ACTIVITY_FORM_ID_MAP,
    ACTIVITY_ICON_MAP,
} from '../../../../../constants/activity.constants';
import {
    ENGAGEMENT_STATE_HIGHLIGHT_MAP,
    ENGAGEMENT_STATE_STATUS_MAP,
} from '../../../../../constants/engagement.constants';
import {
    ICON_CASE,
    ICON_COMMENT,
    ICON_COMMENT_ISSUE,
    ICON_COMMENT_PLUS,
    ICON_CONTEXT_MENU,
    ICON_EDIT,
    ICON_INFO,
    ICON_OPPORTUNITY,
    ICON_TASK,
} from '../../../../../constants/icons.constants';
import { AssignToEvent } from '../../../../../models/assign.model';
import { CaseCardItem } from '../../../../../models/case-list-item.model';
import { EngagementButton } from '../../../../../models/engagement-button.model';
import { EngagementState } from '../../../../../models/engagement-state.model';
import { ListStyle } from '../../../../../models/list.model';
import { SystemEntityRelatedRecordLink } from '../../../../../models/system-entity-related-record-link.model';
import { CommentsService } from '../../../../../shared/features/comments';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-engagement-bar-dumb',
    templateUrl: './organism-engagement-bar-dumb.component.html',
    styleUrls: ['./organism-engagement-bar-dumb.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OrganismEngagementBarComponentDumb {
    @Input() isVisible: boolean;
    @Input() activityId: string;
    @Input() party: Party;
    @Input() engagementState: EngagementState;
    get activity() {
        return this._activity;
    }
    @Input() set activity(activity: Activity) {
        this._activity = activity;

        if (activity) {
            let route = [...URL_INBOX, activity.id];

            if (activity.activityType === this.ACTIVITY_TYPE.WorkItem) {
                const SPLIT_URL =
                    this.router.routerState.snapshot.url.split('/');
                route = [
                    ...URL_CASES,
                    SPLIT_URL[3],
                    ...URL_CASES_WORK_SUB_ITEM,
                    activity.id,
                ];
            }

            if (activity.activityType === this.ACTIVITY_TYPE.Task) {
                route = [...URL_TASKS, activity.id];
            }

            this.routers = route;
        }
    }

    @Input() documentTypes: DocumentType[];
    @Input() set workItem(workItem: WorkItem) {
        this.workItemMenuOutComes = [];
        workItem?.outcomes?.forEach((outcome) => {
            this.workItemMenuOutComes.push({
                label: outcome.name,
                data: outcome,
            } as any);
        });
    }

    @Input() relatedActivitiesCount: number;
    @Input() relatedArticlesCount: number;
    @Input() relatedCasesCount: number;
    @Input() relatedOpportunitiesCount: number;

    @Input() articles: Article[];
    @Input() articlesPaging: Paging;
    @Input() articlesIsLoading: boolean;
    @Input() articlesHasError: boolean;

    @Input() articlesFilterHandler: FilterHandler;

    @Output() closeClick = new EventEmitter();

    @Output() caseLinkButtonClicked =
        new EventEmitter<SystemEntityRelatedRecordLink>();
    @Output() opportunityLinkButtonClicked =
        new EventEmitter<SystemEntityRelatedRecordLink>();

    @Output() deleteCaseLink =
        new EventEmitter<SystemEntityRelatedRecordLink>();
    @Output() deleteOpportunityLink =
        new EventEmitter<SystemEntityRelatedRecordLink>();

    @Output() completeClicked = new EventEmitter();
    @Output() outcomesClicked = new EventEmitter<ProcessOutcome>();
    @Output() assignTo = new EventEmitter<AssignToEvent>();

    _activity: Activity;
    routers = [];

    workItemMenuOutComes: MenuItem[];
    workItemMenuButton = {
        icon: ICON_CONTEXT_MENU.icon,
    };
    popUpTriggers = {
        triggers: 'mouseenter:mouseleave',
        container: 'body',
        openDelay: '100',
        closeDelay: '250',
    };

    readonly ACTIVITY_FORM_ID_MAP = ACTIVITY_FORM_ID_MAP;
    readonly ACTIVITY_ICON_MAP = ACTIVITY_ICON_MAP;
    readonly ACTIVITY_STATUS_TYPE = ActivityStatusType;
    readonly ACTIVITY_TYPE = ActivityType;
    readonly ENGAGEMENT_STATE_HIGHLIGHT_MAP = ENGAGEMENT_STATE_HIGHLIGHT_MAP;
    readonly ENGAGEMENT_STATE_STATUS_MAP = ENGAGEMENT_STATE_STATUS_MAP;
    readonly LIST_STYLE = ListStyle;
    readonly SYSTEM_ENTITY = SystemEntity;

    readonly ICON_SIZE = 'lg';

    readonly ENGAGEMENT_INFORMATION_MODAL_ID =
        'engagement-engagement-information-modal';
    readonly RELATED_RECORDS_MODAL_ID = 'engagement-related-records-modal';
    readonly ARTICLES_MODAL_ID = 'engagement-articles-modal';
    readonly ADD_NEW_ACTIVITY_MODAL_ID = 'engagement-add-new-activity-modal';
    readonly ADD_NEW_CASE_MODAL_ID = 'engagement-add-new-case-modal';
    readonly FeatureFlag = FeatureFlag;

    readonly ICON_COMMENT = ICON_COMMENT.icon;
    readonly ICON_COMMENT_PLUS = ICON_COMMENT_PLUS.icon;
    readonly ICON_COMMENT_ISSUE = ICON_COMMENT_ISSUE.icon;

    constructor(
        public featuresService: FeaturesService,
        private router: Router,
        private commentsService: CommentsService
    ) {}

    buttons(
        activity: Activity,
        context: this,
        relatedActivitiesCount: number,
        relatedArticlesCount: number,
        relatedCasesCount: number,
        relatedOpportunitiesCount: number,
        featuresService: FeaturesService
    ): EngagementButton[] {
        const WORK_ITEM =
            activity.activityType === context.ACTIVITY_TYPE.WorkItem;

        return (
            activity && [
                {
                    label: 'Activity form',
                    icon: ICON_INFO.icon,
                    id: 'engagement-engagement-information',
                    modalId: context.ENGAGEMENT_INFORMATION_MODAL_ID,
                },
                ...(WORK_ITEM
                    ? [
                          {
                              label: 'Comments',
                              icon: context.ICON_COMMENT_PLUS,
                              id: 'engagement-engagement-message',
                              clickEvent: activity,
                          },
                      ]
                    : []),
                ...(!WORK_ITEM
                    ? [
                          {
                              label: 'Notes',
                              icon: ICON_EDIT.icon,
                              id: 'engagement-notes',
                              formSetup: {
                                  formId: ACTIVITY_FORM_ID_MAP[
                                      activity?.activityType
                                  ],
                                  entityId: activity?.id,
                              },
                          },
                      ]
                    : []),
                ...(!WORK_ITEM
                    ? [
                          {
                              label: 'Links related',
                              icon: 'link',
                              id: 'engagement-related-records',
                              count: [
                                  relatedActivitiesCount || 0,
                                  relatedArticlesCount || 0,
                                  relatedCasesCount || 0,
                                  relatedOpportunitiesCount || 0,
                              ].reduce(
                                  (accumulator, value) => accumulator + value,
                                  0
                              ),
                              modalId: context.RELATED_RECORDS_MODAL_ID,
                          },
                      ]
                    : []),
                ...(!WORK_ITEM
                    ? [
                          {
                              icon: 'plus',
                              id: 'engagement-add-new',
                              menu: [
                                  {
                                      label: 'Activity',
                                      id: 'add-new-activity',
                                      icon: ICON_TASK.icon,
                                      modalId:
                                          context.ADD_NEW_ACTIVITY_MODAL_ID,
                                  },
                                  {
                                      label: 'Case',
                                      id: 'add-new-case',
                                      icon: ICON_CASE.icon,
                                      modalId: context.ADD_NEW_CASE_MODAL_ID,
                                  },
                                  ...(featuresService.hasFeature(
                                      FeatureFlag.Pipeline
                                  )
                                      ? [
                                            {
                                                label: 'Opportunity',
                                                id: 'add-new-opportunity',
                                                icon: ICON_OPPORTUNITY.icon,
                                                formSetup: {
                                                    formId: OPPORTUNITY_FORM_ID,
                                                    initialisationParams: {
                                                        ...(activity.id
                                                            ? {
                                                                  activityId:
                                                                      activity.id,
                                                              }
                                                            : {}),
                                                    },
                                                },
                                            },
                                        ]
                                      : []),
                              ],
                          },
                      ]
                    : []),
                ...(!WORK_ITEM
                    ? [
                          {
                              label: 'Articles',
                              icon: 'newspaper',
                              id: 'engagement-articles',
                              modalId: context.ARTICLES_MODAL_ID,
                              feature: FeatureFlag.Articles,
                          },
                      ]
                    : []),
            ]
        );
    }

    buttonClicked(activity: Activity): void {
        this.commentsService.open(activity.id);
    }

    onCloseClick(): void {
        this.closeClick.emit();
    }

    onCaseLinkButtonClicked(caseCard: CaseCardItem) {
        this.caseLinkButtonClicked.emit({
            linkedItemEntityId: caseCard.id,
        });
    }

    onCompleteClicked(event: Event): void {
        event.stopPropagation();
        this.completeClicked.emit();
    }

    onAssignTo(assignToEvent: AssignToEvent) {
        this.assignTo.emit(assignToEvent);
    }

    onOutcomesClicked(outcome: MenuItem): void {
        this.outcomesClicked.emit((outcome as any)?.data);
    }
}

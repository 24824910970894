import { Component, OnInit } from '@angular/core';
import {
    ControlContainer,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { Currency, Locale, customCurrencyMaskConfig } from '@wdx/shared/utils';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-currency-control',
    templateUrl: './form-currency-control.component.html',
})
export class FormCurrencyControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    currencyMaskConfig: Partial<CurrencyMaskConfig>;
    currencySymbol?: string;
    placeholder: string;
    currencyFormGroup = new UntypedFormGroup({
        amount: new UntypedFormControl(null),
        isoCode: new UntypedFormControl(null),
    });

    currencies$: Observable<Currency[]>;
    locale$: Observable<Locale>;

    get amountControl(): UntypedFormControl {
        return this.currencyFormGroup?.get('amount') as UntypedFormControl;
    }

    get isoCodeControl(): UntypedFormControl {
        return this.currencyFormGroup?.get('isoCode') as UntypedFormControl;
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.currencyMaskConfig = {
            ...customCurrencyMaskConfig,
            allowNegative: true,
        };

        this.locale$ = this.dynamicDataService.getMeLocale();
        this.currencies$ = this.dynamicDataService.getCurrencies();

        if (this.isDisabled) {
            this.amountControl.disable();
        }

        combineLatest([this.locale$, this.currencies$])
            .pipe(take(1))
            .subscribe(([locale, currencies]) => {
                this.currencyMaskConfig.decimal =
                    locale?.numberFormat?.numberDecimalSeparator;
                this.currencyMaskConfig.thousands =
                    locale?.numberFormat?.numberGroupSeparator;
                this.placeholder = `${Number(0.0).toLocaleString(locale?.name, {
                    minimumFractionDigits: 2,
                })}`;

                const defaultIsoCode =
                    this.formControl?.value?.isoCode ||
                    locale.currency?.isoCode;

                const defaultCurrency = currencies.find(
                    (currency) => currency.isoCode === defaultIsoCode
                );

                this.currencySymbol =
                    defaultCurrency?.symbol ||
                    locale.currency?.symbol ||
                    locale.currency?.isoCode;

                this.isoCodeControl.setValue(defaultCurrency);

                if (this.formControl?.value?.amount) {
                    this.amountControl.setValue(
                        this.formControl?.value?.amount
                    );
                }
            });

        this.amountControl.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((amount) => {
                this.setValue(amount, this.isoCodeControl.value.isoCode);
                this.formControl?.markAsDirty();
            });

        this.isoCodeControl.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((currency) => {
                this.setValue(this.amountControl.value, currency.isoCode);
                this.formControl?.markAsDirty();
                this.currencySymbol = currency.symbol || currency.isoCode;
            });

        this.formControl?.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((value) => Boolean(value))
            )
            .subscribe((value) => {
                if (value.amount !== this.amountControl.value) {
                    this.amountControl.setValue(value.amount, {
                        emitEvent: false,
                    });
                }

                if (
                    value.isoCode &&
                    value.isoCode !== this.isoCodeControl.value?.isoCode
                ) {
                    this.isoCodeControl.setValue(value.isoCode, {
                        emitEvent: false,
                    });
                    this.currencySymbol = value.isoCode;
                }
            });
    }

    setValue(amount: number, isoCode: string) {
        if (typeof amount === 'number') {
            this.formControl?.setValue({
                amount,
                isoCode,
            });
        } else {
            this.formControl?.setValue(null);
        }
    }

    compareSelectedCurrency(a: Currency, b: Currency) {
        return a.isoCode === b.isoCode;
    }
}

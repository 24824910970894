import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    FilterFieldDefinition,
    Query,
    QueryPaging,
} from '@wdx/clmi/api-models';
import { QueryType } from '@wdx/clmi/api-services/services';
import { Observable, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { PaginationOptions } from '../../../../../../models/pagination-options';
import * as rootReducer from '../../../../../../state/_setup/reducers';
import * as filterActions from '../../state/actions';
import * as filterSelectors from '../../state/selector/filter.selector';
import { SelectType } from '../filter-query/filter-query.static';

@Injectable({
    providedIn: 'root',
})
export class FilterQueryFacadeService {
    resetResults$ = new Subject();

    private store$ = inject(Store<rootReducer.State>);

    getFilterDefinition$(
        queryType: QueryType
    ): Observable<FilterFieldDefinition[]> {
        return this.store$
            .select(filterSelectors.getFilterDefinition, queryType)
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.getDefinitions(queryType);
                    }
                }),
                filter((res) => Boolean(res)),
                take(1)
            );
    }

    getResults(
        queryType: QueryType,
        filters: Query,
        paginationOptions: PaginationOptions = {},
        multipleTypes = false,
        selectType?: SelectType
    ): void {
        if (!multipleTypes) {
            this.store$.dispatch(
                filterActions.getFilterResults({
                    queryType,
                    paginationOptions,
                    filters,
                    selectType: selectType || queryType,
                })
            );
        }

        if (multipleTypes) {
            this.store$.dispatch(
                filterActions.getFilterResultsMergeMap({
                    queryType,
                    paginationOptions,
                    filters,
                    selectType: selectType || queryType,
                })
            );
        }
    }

    getDefinitions(queryType: QueryType): void {
        this.store$.dispatch(
            filterActions.getFilterDefinition({
                queryType,
            })
        );
    }

    /**
     * This will dispatch the filterActions updateFilterResults
     *
     * @param queryType | TypeSearch
     * @param paging | QueryPaging
     * @param query | Query
     */
    updateResults(
        queryType: QueryType,
        paging: QueryPaging,
        query: Query,
        selectType?: SelectType
    ): void {
        if (paging?.page <= paging?.totalPages) {
            this.store$.dispatch(
                filterActions.updateFilterResults({
                    queryType,
                    paginationOptions: {
                        ...paging,
                        pageNumber: paging.page,
                    },
                    filters: query,
                    selectType: selectType || queryType,
                })
            );
        }
    }

    resetResults(queryType: QueryType, selectType?: SelectType): void {
        this.resetResults$.next(true);
        this.store$.dispatch(
            filterActions.resetFilterResults({
                selectType: selectType || queryType,
            })
        );
    }

    /**
     * Use this method to reset the results and fetch it again
     *
     * @param filterType: FilterType
     * @param query: Query
     */
    resetResultsGetRecords(
        queryType: QueryType,
        query: Query,
        paging?: PaginationOptions,
        multipleTypes = false,
        selectType?: SelectType
    ): void {
        this.resetResults(queryType, selectType);
        this.getResults(queryType, query, paging, multipleTypes, selectType);
    }

    selectFirstResults$(selectType: SelectType): Observable<any> {
        return this.store$.select(
            filterSelectors.selectFirstResults,
            selectType
        );
    }

    selectFilterLoading$(selectType: SelectType): Observable<boolean> {
        return this.store$.select(
            filterSelectors.selectFilterLoading,
            selectType
        );
    }

    selectFilterError$(selectType: SelectType): Observable<boolean> {
        return this.store$.select(
            filterSelectors.selectFilterError,
            selectType
        );
    }

    selectFilterResultsSuccess$(selectType: SelectType): Observable<any> {
        return this.store$.select(
            filterSelectors.selectFilterResultsSuccess,
            selectType
        );
    }

    getFilterPaging$(selectType: SelectType): Observable<any> {
        return this.store$.select(filterSelectors.getFilterPaging, selectType);
    }
}

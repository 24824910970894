import { Injectable, inject } from '@angular/core';
import { RouterReducerState } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { RouterStateUrl } from './custom-route-serializer';

@Injectable({ providedIn: 'root' })
export class RouteFacade {
    private store = inject(Store) as Store<{
        router: RouterReducerState<RouterStateUrl>;
    }>;

    getRouterState$(): Observable<RouterStateUrl> {
        return this.store.pipe(
            select('router'),
            map((routerReducerState) => routerReducerState.state)
        );
    }
}

import { createSelector } from '@ngrx/store';
import * as casesReducer from './cases.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    cases: casesReducer.State;
}): casesReducer.State => state.cases;

export const getIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.cases[props.id]?.list
);

export const getIsLoadingSingle = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.isLoadingSingle
);
export const getHasLoadingSingleError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.hasLoadingSingleError
);
export const getSingle = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.cases[props.id]?.single
);

export const getCaseWorkIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.caseWork[props.id]?.isLoadingList
);
export const getCaseWorkHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.caseWork[props.id]?.hasLoadingListError
);
export const getCaseWorkList = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.caseWork[props.id]?.list
);

export const getCaseHistoryIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.caseHistory[props.id]?.isLoadingList
);
export const getCaseHistoryHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.caseHistory[props.id]?.hasLoadingListError
);
export const getCaseHistoryList = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.caseHistory[props.id]?.list
);

export const getIsLoadingPage = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.isLoadingPage
);
export const getHasLoadingPageError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.hasLoadingPageError
);
export const getInfinityPaging = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.infinity?.paging
);
export const getInfinityCombinedList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.infinity?.combinedList
);

export const getClientCasesIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.clientCases[props.id]?.isLoadingList
);
export const getClientCasesHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.clientCases[props.id]?.hasLoadingListError
);
export const getClientCases = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.clientCases[props.id]?.list
);
export const getAssignedTeamMembersForCase = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.assignees[props.id]?.list
);
export const getAssignedTeamMembersForCaseIsLoadingPage = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.assignees[props.id]?.isLoadingPage
);
export const getAssignedTeamMembersForCaseHasLoadingPageError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.assignees[props.id]?.hasLoadingPageError
);
export const completeCase = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.cases[props.id]?.single
);
export const completeCaseIsLoadingSingle = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.isLoadingSingle
);
export const completeCaseHasLoadingSingleError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.hasLoadingSingleError
);
export const cancelCase = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.cases[props.id]?.single
);
export const cancelCaseIsLoadingSingle = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.cases[props.id]?.isUpdating
);
export const cancelCaseHasLoadingSingleError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.cases[props.id]?.hasUpdatingError
);
export const getCaseCategories = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.categories[props.id]?.list
);
export const getCaseCategoriesIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.categories[props.id]?.isLoadingList
);
export const getCaseCategoriesHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.categories[props.id]?.hasLoadingListError
);

/**
 * Reducer property selectors
 */
export const getVisibilityForIdIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.visibility[props.id]?.isLoadingList
);
export const getVisibilityForIdHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.visibility[props.id]?.hasLoadingListError
);
export const getVisibilityForId = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.visibility[props.id]?.list
);

export const getReviewForIdIsLoadingList = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.reviews[props.id]?.isLoadingList
);

export const getReviewForIdHasLoadingListError = createSelector(
    getState,
    (state: casesReducer.State, props: any) =>
        state.reviews[props.id]?.hasLoadingListError
);

export const getReviewForId = createSelector(
    getState,
    (state: casesReducer.State, props: any) => state.reviews[props.id]?.list
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomAvatarModule } from '../../../components/atoms/atom-avatar/atom-avatar.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeAvatarGroupContainerComponent } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group-container/molecule-avatar-group-container.component';
import { MoleculeAvatarGroupComponent } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group/molecule-avatar-group.component';
import { MoleculeAvatarInfoModule } from '../../../components/molecules/molecule-avatar-info/molecule-avatar-info.module';
import { MoleculeAvatarModule } from '../../../components/molecules/molecule-avatar/molecule-avatar.module';
import { MoleculeContextMenuModule } from '../../../components/molecules/molecule-context-menu/molecule-context-menu.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        AtomAvatarModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeAvatarInfoModule,
        MoleculeAvatarModule,
        MoleculeContextMenuModule,
        NgbDropdownModule,
        OrganismModalModule,
        PipesModule,
        RouterModule,
    ],
    declarations: [
        MoleculeAvatarGroupComponent,
        MoleculeAvatarGroupContainerComponent,
    ],
    exports: [
        MoleculeAvatarGroupComponent,
        MoleculeAvatarGroupContainerComponent,
    ],
})
export class MoleculeAvatarGroupModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fn' })
export class FunctionPipe implements PipeTransform {
    transform(
        value: any,
        callback: (...args: any[]) => any,
        ...args: any[]
    ): any {
        if (!callback) {
            return null;
        }

        const result = callback.apply(null, [value, ...args]);

        return result;
    }
}

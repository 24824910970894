import { AppStatus, Regarding } from '@wdx/clmi/api-models';

export enum TriggerChar {
    'At' = '@',
    'Hash' = '#',
}

export interface IAppMention {
    displayName?: string;
    app: AppStatus;
    caseId: string;
    workItemId: string;
    regarding: Regarding[];
    triggerChar: TriggerChar;
}

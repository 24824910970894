import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldDefinitionOption } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-ff-summary-info-cards',
    templateUrl: './form-summary-info-cards.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSummaryInfoCardsComponent {
    @Input() infoCards: FieldDefinitionOption[];
}

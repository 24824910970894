import { Injectable } from '@angular/core';

import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import {
    AppStatus,
    AppType,
    Case,
    CaseType,
    DocumentTemplate,
    HistoryEntry,
    Process,
    SystemEntity,
    WorkItem,
    WorkItemCategorySummary,
} from '@wdx/clmi/api-models';

import { WdxDestroyClass } from '@wdx/shared/utils';
import {
    IAppMention,
    TriggerChar,
} from '../../../../shared/features/comments/shared/constants/mention.constant';
import * as rootReducer from '../../../../state/_setup/reducers';
import * as caseTypesActions from '../../../../state/case-types/case-types.actions';
import * as caseTypesSelectors from '../../../../state/case-types/case-types.selectors';
import * as casesActions from '../../../../state/cases/cases.actions';
import * as casesSelectors from '../../../../state/cases/cases.selectors';
import * as documentTemplatesActions from '../../../../state/document-templates/document-templates.actions';
import * as documentTemplatesSelectors from '../../../../state/document-templates/document-templates.selectors';
import * as processesActions from '../../../../state/processes/processes.actions';
import * as processesSelectors from '../../../../state/processes/processes.selectors';

@Injectable({
    providedIn: 'root',
})
export class CasesFacadeService extends WdxDestroyClass {
    casesByEntityIds: string[] = [];
    caseId: string;
    case$ = new BehaviorSubject<Case>(null);
    case: Case;
    caseIds: string[] = [];
    caseWorkIds: string[] = [];
    caseProcessIds: string[] = [];
    caseCategoryIds: string[] = [];
    caseTeamMembersIds: string[] = [];
    reloadCase$ = new Subject();

    isActiveCase$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    constructor(
        private store$: Store<rootReducer.State>,
        private actionsSubject$: ActionsSubject
    ) {
        super();
    }

    /**
     * Cases
     */
    getCases$(entityType: SystemEntity, entityId: string): Observable<Case[]> {
        return this.store$
            .select(casesSelectors.getList, { id: entityId })
            .pipe(
                tap(() => {
                    if (!this.casesByEntityIds.includes(entityId)) {
                        this.loadCases(entityType, entityId);
                        this.casesByEntityIds.push(entityId);
                    }
                }),
                filter((res) => Boolean(res))
            );
    }

    getCasesIsLoading$(entityId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.getIsLoadingList, {
            id: entityId,
        });
    }

    getCasesHasError$(entityId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.getHasLoadingListError, {
            id: entityId,
        });
    }

    loadCases(entityType: SystemEntity, entityId: string): void {
        this.store$.dispatch(casesActions.getCases({ entityType, entityId }));
    }

    /**
     * Case
     */
    getCase$(caseId: string): Observable<Case> {
        return this.store$
            .select(casesSelectors.getSingle, { id: caseId })
            .pipe(
                tap(() => {
                    if (!this.caseIds.includes(caseId)) {
                        this.loadCase(caseId);
                        this.caseIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res)),
                tap((value: Case) => {
                    this.case$.next(value);
                    this.case = value;
                })
            );
    }

    loadCase(caseId: string): void {
        this.store$.dispatch(casesActions.getCase({ caseId }));
    }

    getAppsForCase$(caseId: string): Observable<AppStatus[]> {
        return this.getCaseWork$(caseId).pipe(
            map((caseWork) => caseWork.flatMap((item) => item.apps))
        );
    }

    getAppByType$(caseId: string, appType: AppType): Observable<AppStatus> {
        return this.getAppsForCase$(caseId).pipe(
            map((apps) => apps.find((app) => app.type === appType))
        );
    }

    /**
     * CaseWork
     */
    getCaseWork$(caseId: string): Observable<WorkItem[]> {
        return this.store$
            .select(casesSelectors.getCaseWorkList, {
                id: caseId,
            })
            .pipe(
                tap(() => {
                    if (!this.caseWorkIds.includes(caseId)) {
                        this.loadCaseWork(caseId);
                        this.caseWorkIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res))
            );
    }

    loadCaseWork(caseId: string): void {
        this.store$.dispatch(casesActions.getCaseWork({ caseId: caseId }));
    }

    getProcesses$(caseId: string): Observable<Process[]> {
        return this.store$
            .select(processesSelectors.getProcessesForCase, { id: caseId })
            .pipe(
                tap(() => {
                    if (!this.caseProcessIds.includes(caseId)) {
                        this.loadProcesses(caseId);
                        this.caseProcessIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res))
            );
    }

    loadProcesses(caseId: string) {
        this.store$.dispatch(
            processesActions.getProcessesForCase({
                caseId,
            })
        );
    }

    getCategories$(caseId: string): Observable<WorkItemCategorySummary[]> {
        return this.store$
            .select(casesSelectors.getCaseCategories, {
                id: caseId,
            })
            .pipe(
                tap(() => {
                    if (!this.caseCategoryIds.includes(caseId)) {
                        this.loadCategories(caseId);
                        this.caseCategoryIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res))
            );
    }

    loadCategories(caseId: string) {
        this.store$.dispatch(
            casesActions.getCaseCategories({
                caseId,
            })
        );
    }

    /**
     * CaseHistory
     */
    getCaseHistory$(caseId: string): Observable<HistoryEntry[]> {
        this.store$.dispatch(casesActions.getCaseHistory({ caseId }));
        return this.store$
            .select(casesSelectors.getCaseHistoryList, {
                id: caseId,
            })
            .pipe(filter((res) => Boolean(res)));
    }

    /**
     * Case Types
     */
    getCaseTypes$(): Observable<CaseType[]> {
        return this.store$.select(caseTypesSelectors.getList).pipe(
            tap((res) => {
                if (!res) {
                    this.store$.dispatch(
                        caseTypesActions.getAll({ isActive: true })
                    );
                }
            }),
            filter((res) => Boolean(res))
        );
    }

    /**
     * Document Templates
     */
    getDocumentTemplates$(): Observable<DocumentTemplate[]> {
        return this.store$
            .select(
                documentTemplatesSelectors.getDocumentTemplatesList({
                    id: SystemEntity.Case,
                })
            )
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.store$.dispatch(
                            documentTemplatesActions.getDocumentTemplates({
                                entityType: SystemEntity.Case,
                            })
                        );
                    }
                }),
                filter((res) => Boolean(res))
            );
    }

    /**
     * Statuses
     */
    getCompleteCaseIsLoading(caseId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.completeCaseIsLoadingSingle, {
            id: caseId,
        });
    }

    checkIfIsActiveCase(caseId: string): void {
        this.getCase$(caseId)
            .pipe(
                takeUntil(this.destroyed$),
                filter((caseItem) => !!caseItem)
            )
            .subscribe((caseItem) => {
                this.isActiveCase$.next(caseItem.isActive);
            });
    }

    listenToCaseUpdatedEvent$(): Observable<any> {
        return this.actionsSubject$.pipe(
            ofType(casesActions.caseUpdated),
            filter((action: any) => action.caseId === this.caseId)
        );
    }

    getListOfAppsForCase$(caseId: string): Observable<IAppMention[]> {
        return this.getCaseWork$(caseId).pipe(
            map((caseWork) =>
                caseWork
                    .flatMap((item) =>
                        item.apps?.map((app) => ({
                            app,
                            regarding: item.regarding,
                            workItemId: item.id,
                        }))
                    )
                    .filter(Boolean)
            ),
            map((appsInfo) => {
                return appsInfo.map((appInfo) => {
                    return {
                        app: appInfo.app,
                        caseId: caseId,
                        displayName:
                            appsInfo.filter(
                                (a) => a.app.name === appInfo.app.name
                            ).length > 1
                                ? `${appInfo.app.name} (${appInfo.regarding[0].name})`
                                : appInfo.app.name,
                        regarding: appInfo.regarding,
                        workItemId: appInfo.workItemId,
                        name: appInfo.app.name,
                        triggerChar: TriggerChar.Hash,
                    } as IAppMention;
                });
            })
        );
    }
}

<label class="input-switch" [for]="instanceId">
    <input
        class="switch-checkbox"
        type="checkbox"
        [id]="instanceId"
        [checked]="toggled"
        [disabled]="disabled"
        (change)="toggleSwitch()"
    />
    <div class="switch"></div>
</label>

import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { ROUTE_PREFIX, Severity, TenantsService } from '@wdx/shared/utils';

import { UserApiService } from '../user.service';
import * as userActions from './user.actions';

@Injectable()
export class UserEffects {
    private actions$ = inject(Actions);
    private userApiService = inject(UserApiService);
    private toastService = inject(WdxToastService);
    private location = inject(Location);
    private tenantsService = inject(TenantsService);

    getMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getMeAction),
            mergeMap(() =>
                this.userApiService.getMe().pipe(
                    map((user) =>
                        userActions.getMeSuccessAction({
                            user,
                        })
                    ),
                    catchError((error) =>
                        of(userActions.getMeFailureAction({ error }))
                    )
                )
            )
        )
    );

    getMeLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getMeLocaleAction),
            mergeMap(() =>
                this.userApiService.getMeLocale().pipe(
                    map((meLocale) => {
                        return userActions.getMeLocaleSuccessAction({
                            meLocale,
                        });
                    }),
                    catchError((error) =>
                        of(userActions.getMeLocaleFailureAction({ error }))
                    )
                )
            )
        )
    );

    changeTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.changeTenantAction),
            mergeMap((action) =>
                this.userApiService.changeTenant(action.tenantCode).pipe(
                    map(() =>
                        userActions.changeTenantSuccessAction({
                            tenantCode: action.tenantCode,
                        })
                    ),
                    catchError((error) =>
                        of(userActions.changeTenantFailureAction({ error }))
                    )
                )
            )
        )
    );

    changeTenantSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.changeTenantSuccessAction),
                tap((action) => {
                    this.tenantsService.setChangeTenantCode(action.tenantCode);
                    this.location.replaceState(ROUTE_PREFIX[0]);
                    window.location.reload();
                })
            ),
        { dispatch: false }
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getForIdAction),
            mergeMap((action) =>
                this.userApiService.getForId(action.userId).pipe(
                    map((user) =>
                        userActions.getForIdSuccessAction({
                            user,
                        })
                    ),
                    catchError((error) =>
                        of(
                            userActions.getForIdFailureAction({
                                userId: action.userId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getHistoryForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getHistoryForIdAction),
            mergeMap((action) =>
                this.userApiService.getHistoryForId(action.userId).pipe(
                    map((history) =>
                        userActions.getHistoryForIdSuccessAction({
                            history,
                            userId: action.userId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            userActions.getHistoryForIdFailureAction({
                                userId: action.userId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKPIsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.getKPIsForIdAction),
            mergeMap((action) =>
                this.userApiService.getKPIsForId(action.userId).pipe(
                    map((kpi) =>
                        userActions.getKPIsForIdSuccessAction({
                            kpi,
                            userId: action.userId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            userActions.getKPIsForIdFailureAction({
                                userId: action.userId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.changePasswordAction),
            mergeMap((action) =>
                this.userApiService
                    .changePassword(action.oldPassword, action.newPassword)
                    .pipe(
                        map(() => userActions.changePasswordSuccessAction()),
                        catchError((error) =>
                            of(userActions.changePasswordErrorAction({ error }))
                        )
                    )
            )
        )
    );

    changePasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.changePasswordSuccessAction),
                tap(() => {
                    this.toastService.show({
                        body: ['Password successfully changed'],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    changeAppLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.changeAppLanguageAction),
            switchMap((action) =>
                this.userApiService.changeAppLanguage(action.languageCode).pipe(
                    map(() => userActions.changeAppLanguageSuccessAction()),
                    catchError((error) =>
                        of(
                            userActions.changeAppLanguageFailureAction({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    changeAppLanguageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.changeAppLanguageSuccessAction),
                tap(() => {
                    window.location.reload();
                })
            ),
        { dispatch: false }
    );

    changeAppCurrency$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.changeAppCurrencyAction),
            switchMap((action) =>
                this.userApiService.changeAppCurrency(action.isoCode).pipe(
                    map(() => userActions.changeAppCurrencySuccessAction()),
                    catchError((error) =>
                        of(
                            userActions.changeAppCurrencyFailureAction({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    changeAppCurrencySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(userActions.changeAppCurrencySuccessAction),
                tap(() => {
                    window.location.reload();
                })
            ),
        { dispatch: false }
    );
}

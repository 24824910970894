import { Injectable } from '@angular/core';
import { LocalStorageService } from './../local-storage/local-storage.service';
import { UserTenant } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class TenantsService {
    static TENANT_KEY = 'Tenant';

    private tenants: UserTenant[] | undefined;

    private _tenantCode = '';

    get tenantCode() {
        return this._tenantCode;
    }

    set tenantCode(tenantCode: string) {
        this._tenantCode = tenantCode;
    }

    constructor(private localStorageService: LocalStorageService) {
        const changeTenantCode = this.localStorageService.getStringKey(
            TenantsService.TENANT_KEY
        );

        if (changeTenantCode) {
            this.tenantCode = changeTenantCode;
        }
    }

    set(tenants: UserTenant[]) {
        this.tenants = tenants;
    }

    get(): UserTenant[] | undefined {
        return this.tenants;
    }

    setChangeTenantCode(tenantCode: string) {
        this.localStorageService.setStringKey(
            TenantsService.TENANT_KEY,
            tenantCode
        );
    }

    clearChangeTenantCode() {
        this.localStorageService.setStringKey(TenantsService.TENANT_KEY, null);
    }
}

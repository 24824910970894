import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Activity,
    ArticleLinkedRecord,
    Case,
    PartyStub,
} from '@wdx/clmi/api-models';
import { URL_CASES, URL_INBOX } from '@wdx/shared/utils';
import { ACTIVITY_ICON_MAP } from '../../../../../constants/activity.constants';
import { MEDIA_BREAKPOINT_LG } from '../../../../../constants/breakpoints.constants';
import {
    ICON_CASE,
    ICON_LIKE_BASE,
    ICON_LINK,
} from '../../../../../constants/icons.constants';
import { InfoCardData } from '../../../../../models/info-card-data.model';
import { RandomStringPipe } from '../../../../../pipes/random-string.pipe';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-engagements-dumb',
    templateUrl: './molecule-engagements-dumb.component.html',
})
export class MoleculeEngagementsDumbComponent implements OnInit, OnChanges {
    @Input() links: number;
    @Input() likes: number;
    @Input() showText: boolean;
    @Input() hasInfoModal: boolean;
    @Input() infoModalTitle: string;

    @Input() linkedActivities: Activity[];
    @Input() linkedActivitiesIsLoading: boolean;
    @Input() linkedActivitiesHasError: boolean;

    @Input() linkedCases: Case[];
    @Input() linkedCasesIsLoading: boolean;
    @Input() linkedCasesHasError: boolean;

    @Input() likedBy: PartyStub[];
    @Input() likedByIsLoading: boolean;
    @Input() likedByHasError: boolean;

    @Output() getLinksAndLikes = new EventEmitter();

    readonly ICON_LIKE = ICON_LIKE_BASE.icon;
    readonly ICON_LINK = ICON_LINK.icon;
    readonly ICON_CASE = ICON_CASE.icon;
    readonly TABS_ID = 'engagements-more-info';

    instanceId: string;
    responsiveShowText: boolean;

    @HostListener('window:resize') onResize() {
        this.overrideShowText();
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
        this.overrideShowText();
    }

    ngOnChanges() {
        this.overrideShowText();
    }

    overrideShowText(): void {
        this.responsiveShowText =
            window.innerWidth <= MEDIA_BREAKPOINT_LG ? false : this.showText;
    }

    linkedAsInfoCards(
        records: ArticleLinkedRecord[],
        activities: Activity[],
        cases: Case[]
    ): InfoCardData[] {
        const allLinked = [...records, ...activities, ...cases];
        return allLinked.map(() => ({
            primaryInfo: '',
        }));
    }

    onGetLinksAndLikes(tabIndex: number): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                ['tab-' + this.TABS_ID]: tabIndex,
            },
            queryParamsHandling: 'merge',
        });
        this.getLinksAndLikes.emit();
    }

    casesAndActivitiesAsInfoCards(
        activities: Activity[],
        cases: Case[]
    ): InfoCardData[] {
        let caseCards = [];
        let activityCards = [];

        if (cases?.length) {
            caseCards = cases.map((caseCard) => ({
                primaryInfo: caseCard.subject,
                secondaryInfo: { label: caseCard.regarding.name },
                icon: ICON_CASE.icon,
                routerLink: [...URL_CASES, caseCard.id],
            }));
        }

        if (activities?.length) {
            activityCards = activities.map((activityCard) => ({
                primaryInfo: activityCard.activityType,
                secondaryInfo: { label: activityCard.subject },
                icon: ACTIVITY_ICON_MAP[activityCard.activityType],
                routerLink: [...URL_INBOX, activityCard.id],
            }));
        }

        return [...caseCards, ...activityCards].sort((a, b) =>
            a.primaryInfo.localeCompare(b.primaryInfo)
        );
    }

    likedByAsInfoCards(likedBy: PartyStub[]): InfoCardData[] {
        if (!likedBy?.length) {
            return;
        }

        return likedBy.map((party) => ({
            primaryInfo: party.name,
            avatars: [new PartyToAvatarPipe().transform(party)],
        }));
    }
}

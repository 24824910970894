export const CONTEXTUAL = 'contextual';
export const IS_FAVOURITE = 'isFavourite';

export const REMOVE_NAME = (data) => {
    if (Array.isArray(data)) {
        return data.map((item) => REMOVE_NAME(item));
    }

    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { name, ...newExpression } = data;

        return newExpression;
    }

    return data;
};

export const EXPRESSIONS_LOOP = (filters) => {
    filters = REMOVE_IS_FAVOURITE_PROPERTY(filters);
    if (
        !filters ||
        (filters && !filters?.expressions && !filters?.expressions?.length)
    ) {
        return filters;
    }

    const EXPRESSIONS = filters?.expressions?.map((filter) => {
        if (
            // eslint-disable-next-line no-prototype-builtins
            !filter.hasOwnProperty('values') &&
            // eslint-disable-next-line no-prototype-builtins
            !filter.hasOwnProperty('value')
        ) {
            return filter;
        }

        filter = REMOVE_CONTEXTUAL_PROPERTY(filter);

        let values;
        let value;

        // eslint-disable-next-line no-prototype-builtins
        if (filter.hasOwnProperty('value')) {
            value = filter?.value;

            if (
                typeof filter?.value === 'object' &&
                !Array.isArray(filter?.value) &&
                filter?.value !== null
            ) {
                value = REMOVE_NAME(filter.value);
            }
        }

        // eslint-disable-next-line no-prototype-builtins
        if (filter.hasOwnProperty('values')) {
            if (Array.isArray(filter?.values?.[0])) {
                values = filter?.values[0];
            } else {
                values = filter?.values;
            }

            if (
                Array.isArray(values) ||
                (typeof values === 'object' &&
                    !Array.isArray(values) &&
                    values !== null)
            ) {
                values = REMOVE_NAME(values);
            }
        }

        return { ...filter, values, value };
    });

    return {
        ...filters,
        expressions: EXPRESSIONS,
    };
};

export const REMOVE_CONTEXTUAL_PROPERTY = (data) => {
    if (!data || Array.isArray(data) || typeof data !== 'object') {
        return data;
    }

    const OBJ = { ...data };
    // eslint-disable-next-line no-prototype-builtins
    if (OBJ && OBJ?.hasOwnProperty(CONTEXTUAL)) {
        delete OBJ[CONTEXTUAL];
    }

    return OBJ;
};

export const REMOVE_IS_FAVOURITE_PROPERTY = (data) => {
    if (!data || Array.isArray(data) || typeof data !== 'object') {
        return data;
    }

    const OBJ = { ...data };
    // eslint-disable-next-line no-prototype-builtins
    if (OBJ && OBJ?.hasOwnProperty(IS_FAVOURITE)) {
        delete OBJ[IS_FAVOURITE];
    }

    return OBJ;
};

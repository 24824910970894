import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IFormDynamicData } from '../../../../interfaces';

@Injectable()
export class AddressFormatService {
    private dynamicDataService = inject(IFormDynamicData);

    /**
     * Returns format friendly address
     * @param fieldValue
     */
    getAddress$(fieldValue: any, useAddressLine = false): Observable<string[]> {
        if (!fieldValue) {
            return of([]);
        }

        const addressLine =
            fieldValue['addressLine'] && useAddressLine
                ? ['addressLine']
                : ['buildingName', 'streetBuildingIdentification', 'street'];
        const addressFields = [
            'department',
            'subDepartment',
            'floor',
            'postOfficeBox',
            'room',
            'townLocationName',
            'districtName',
            'city',
            'region',
            'postCode',
            'countryCode',
        ];

        return this.dynamicDataService.getCountries().pipe(
            take(1),
            map((countries) => {
                const addressString = addressLine
                    .map((field) => fieldValue[field])
                    .filter((field) => Boolean(field))
                    .join(', ');

                const formattedAddressFields = addressFields
                    .map((field) => {
                        if (field === 'countryCode') {
                            return countries.find(
                                (country) =>
                                    country.isoCode === fieldValue[field]
                            )?.name;
                        } else {
                            return fieldValue[field];
                        }
                    })
                    .filter((field) => Boolean(field));

                return [addressString, ...formattedAddressFields];
            })
        );
    }
}

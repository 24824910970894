import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputLookupModule } from '../../../components/molecules/molecule-input-lookup/molecule-input-lookup.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeActionButtonModule } from '../../molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeOpportunityCardModule } from '../../molecules/molecule-opportunity-card/molecule-opportunity-card.module';
import { OrganismCardsListModule } from '../organism-cards-list/organism-cards-list.module';
import { OrganismOpportunitiesListComponent } from './organism-opportunities-list.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeInputLookupModule,
        MoleculeOpportunityCardModule,
        OrganismCardsListModule,
        PipesModule,
    ],
    declarations: [OrganismOpportunitiesListComponent],
    exports: [OrganismOpportunitiesListComponent],
})
export class OrganismOpportunitiesListModule {}

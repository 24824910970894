import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    NgbAccordionModule,
    NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomCommentStatusModule } from '../../atoms/atom-comment-status/atom-comment-status.module';
import { MoleculeAvatarGroupModule } from '../molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeAccordionComponent } from './molecule-accordion.component';

@NgModule({
    imports: [
        AtomCommentStatusModule,
        CommonModule,
        MoleculeAvatarGroupModule,
        NgbAccordionModule,
        NgbPopoverModule,
        PipesModule,
        RouterModule,
        WdxIconModule,
    ],
    declarations: [MoleculeAccordionComponent],
    exports: [MoleculeAccordionComponent],
})
export class MoleculeAccordionModule {}

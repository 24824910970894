import { Injectable, inject } from '@angular/core';
import {
    Document,
    FilterOperator,
    QueryExpression,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { DOCUMENT_FORM_ID, FeaturesService } from '@wdx/shared/utils';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICON_ADD } from '../../../../../constants/icons.constants';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { DialogMode } from '../../../../../models/dialog.model';
import { DialogService } from '../../../../../services/dialog.service';
import { FilterQueryFacadeService } from '../../../../../shared/features/form-filters';
import * as documentsActions from '../../../../../state/documents/documents.actions';

@Injectable({
    providedIn: 'root',
})
export class FiledDocumentsService {
    private filterQueryFacadeService = inject(FilterQueryFacadeService);
    private dialogService = inject(DialogService);
    private featuresService = inject(FeaturesService);

    private get isDocumentManagerEnabled() {
        return this.featuresService.hasFeature(FeatureFlag.DocumentManagement);
    }

    get isFiledDocumentsEnabled() {
        return this.isDocumentManagerEnabled;
    }

    getContextual(entityType: SystemEntity, entityId: string): QueryExpression {
        return {
            field: 'regarding',
            operator: FilterOperator.Equals,
            contextual: true,
            values: [this.getContextualValue(entityType, entityId)],
        } as QueryExpression;
    }

    getTotalDocumentCount$() {
        return combineLatest([
            this.filterQueryFacadeService.getFilterPaging$(
                SystemEntity.Document
            ),
        ]).pipe(
            map(([paging]) =>
                paging?.totalRecords > 0 ? paging?.totalRecords : 0
            )
        );
    }

    getAddDocumentActionButton(
        entityId: string,
        entityType: SystemEntity
    ): ActionButton {
        if (entityId && entityType) {
            return {
                mode: ActionButtonMode.IconButton,
                cySelector: 'btn-add-document',
                icon: ICON_ADD.icon,
                formSetup: {
                    formId: DOCUMENT_FORM_ID,
                    initialisationParams: {
                        [`${entityType.replace(/\b(\w)/g, (s) =>
                            s.toLowerCase()
                        )}Id`]: entityId,
                    },
                },
            };
        }
        return null;
    }

    deleteDocument(document: Document) {
        this.dialogService.open({
            dialogMode: DialogMode.DeleteDocument,
            confirmAction: documentsActions.deleteDocument({
                documentId: document.id,
            }),
            data: { name: document.name },
        });
    }

    private getContextualValue(entityType: SystemEntity, entityId: string) {
        return {
            id: entityId,
            lookupType: entityType,
        };
    }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { RouteFacade, RouterStateUrl } from '@wdx/clmi/api-services/state';
import {
    FeaturesService,
    FormDisplayMode,
    NavItem,
    Privilege,
} from '@wdx/shared/utils';
import { filter, take } from 'rxjs';
import { BaseSearchBarClass } from '../../../classes/base-search-bar.class';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP } from '../../../shared/components/hybrid-select-menu/hybrid-select-menu.constant';
import { IMPORT_ENTITY_FEATURE_FLAGS_MAP } from '../../../shared/components/import-entities/import-entities.constant';
import { FilterQueryService } from '../../../shared/features/form-filters';
import { RouteDataService } from '../../../shared/services/route-data/route-data.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-search-and-filter-bar',
    templateUrl: './molecule-search-and-filter-bar.component.html',
})
export class MoleculeSearchAndFilterBarComponent
    extends BaseSearchBarClass
    implements OnInit
{
    @Output() sort = new EventEmitter<string>();

    instanceId: string;
    showImportExport: boolean;
    showImport: boolean;
    importPrivilege!: Privilege;
    exportPrivilege!: Privilege;

    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly FEATURE_FLAG = FeatureFlag;

    constructor(
        public routeDataService: RouteDataService,
        private featuresService: FeaturesService,
        private filterQueryService: FilterQueryService,
        private routeFacade: RouteFacade
    ) {
        super();
    }

    ngOnInit() {
        this.routeFacade
            .getRouterState$()
            .pipe(
                take(1),
                filter((routerState) =>
                    Boolean(routerState.data?.dropdownMenuPrivileges)
                )
            )
            .subscribe((routerState: RouterStateUrl) => {
                this.importPrivilege =
                    routerState.data?.dropdownMenuPrivileges?.import;
                this.exportPrivilege =
                    routerState.data?.dropdownMenuPrivileges?.export;
            });
        this.instanceId = new RandomStringPipe().transform();

        this.showImportExport = Boolean(
            this.featuresService.hasFeature(FeatureFlag.Exports) ||
                Object.values(IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP).some(
                    (featureFlag) =>
                        this.featuresService.hasFeature(
                            featureFlag as FeatureFlag
                        )
                )
        );

        this.showImport = Object.keys(IMPORT_ENTITY_FEATURE_FLAGS_MAP).some(
            (entity) =>
                this.filterQueryService?.viewType === entity &&
                this.featuresService.hasFeature(
                    IMPORT_ENTITY_FEATURE_FLAGS_MAP[entity]
                )
        );
    }

    onSort(sortMenuItem: NavItem): void {
        this.sort.emit(sortMenuItem?.id);
    }
}

import { createAction, props } from '@ngrx/store';
import {
    AppStatus,
    DocumentRequirement,
    DocumentRequirementType,
    SystemEntity,
} from '@wdx/clmi/api-models';

export const getDocumentRequirementTypes = createAction(
    '[Document Checklist] Get Document Requirement Types',
    props<{
        app: AppStatus;
    }>()
);

export const getDocumentRequirementTypesSuccess = createAction(
    '[Document Checklist] Get Document Requirement Types Success',
    props<{
        documentRequirementTypes: DocumentRequirementType[];
    }>()
);

export const getDocumentRequirementTypesFailure = createAction(
    '[Document Checklist] Get Document Requirement Types Failure',
    props<{
        error: Error;
    }>()
);

export const addDocumentRequirement = createAction(
    '[Document Checklist] Add Document Requirement',
    props<{
        clientId: string;
        documentRequirementTypeCode: string;
        systemEntity: SystemEntity;
        partyId?: string;
    }>()
);

export const addDocumentRequirementSuccess = createAction(
    '[Document Checklist] Add Document Requirement Success',
    props<{
        clientId: string;
        documentRequirementTypeCode: string;
        partyId?: string;
    }>()
);

export const addDocumentRequirementError = createAction(
    '[Document Checklist] Add Document Requirement Error',
    props<{
        clientId: string;
        documentRequirementTypeCode: string;
        partyId?: string;
        error: Error;
    }>()
);

export const generateDocumentFromTemplate = createAction(
    '[Document Checklist] Generate Document From Template',
    props<{
        payload: {
            documentRequirementId: string;
            documentTemplateId: string;
        }[];
    }>()
);

export const generateDocumentFromTemplateSuccess = createAction(
    '[Document Checklist] Generate Document From Template Success',
    props<{
        payload: {
            documentRequirementId: string;
            documentTemplateId: string;
        }[];
    }>()
);

export const generateDocumentFromTemplateError = createAction(
    '[Document Checklist] Generate Document From Template Error',
    props<{
        payload: {
            documentRequirementId: string;
            documentTemplateId: string;
        }[];
        error: Error;
    }>()
);

export const approveDocumentRequirement = createAction(
    '[Document Checklist] Approve Document Requirement',
    props<{
        documentRequirementIds: string[];
        approve: boolean;
    }>()
);

export const approveDocumentRequirementSuccess = createAction(
    '[Document Checklist] Approve Document Requirement Success',
    props<{
        documentRequirementIds: string[];
        approve: boolean;
    }>()
);

export const approveDocumentRequirementError = createAction(
    '[Document Checklist] Approve Document Requirement Error',
    props<{
        documentRequirementIds: string[];
        error: Error;
    }>()
);

export const cancelDocumentRequirement = createAction(
    '[Document Checklist] Cancel Document Requirement',
    props<{
        documentRequirementIds: string[];
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const cancelDocumentRequirementSuccess = createAction(
    '[Document Checklist] Cancel Document Requirement Success',
    props<{
        documentRequirementIds: string[];
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const cancelDocumentRequirementError = createAction(
    '[Document Checklist] Cancel Document Requirement Error',
    props<{
        documentRequirementIds: string[];
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>()
);

export const deleteDocumentRequirement = createAction(
    '[Document Checklist] Delete Document Requirement',
    props<{
        documentRequirementId: string;
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const deleteDocumentRequirementSuccess = createAction(
    '[Document Checklist] Delete Document Requirement Success',
    props<{
        documentRequirementId: string;
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const deleteDocumentRequirementError = createAction(
    '[Document Checklist] Delete Document Requirement Error',
    props<{
        documentRequirementId: string;
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>()
);

export const sendDocumentRequirement = createAction(
    '[Document Checklist] Send Document Requirement',
    props<{
        documentRequirementIds: string[];
    }>()
);

export const sendDocumentRequirementSuccess = createAction(
    '[Document Checklist] Send Document Requirement Success',
    props<{
        documentRequirementIds: string[];
    }>()
);

export const sendDocumentRequirementError = createAction(
    '[Document Checklist] Send Document Requirement Error',
    props<{
        documentRequirementIds: string[];
        error: Error;
    }>()
);

export const linkDocumentToRequirement = createAction(
    '[Document Checklist] Link Document To Requirement',
    props<{
        documentRequirementId: string;
        documentId: string;
        isLinked: boolean;
    }>()
);

export const linkDocumentToRequirementSuccess = createAction(
    '[Document Checklist] Link Document To Requirement Success',
    props<{
        documentRequirementId: string;
        documentId: string;
        isLinked: boolean;
    }>()
);

export const linkDocumentToRequirementError = createAction(
    '[Document Checklist] Link Document To Requirement Error',
    props<{
        documentRequirementId: string;
        documentId: string;
        error: Error;
    }>()
);

export const getDocumentRequirements = createAction(
    '[Document Checklist] Get Document Requirements ',
    props<{
        entityId: string;
        entityType: SystemEntity;
        data: string[];
    }>()
);

export const getDocumentRequirementsSuccess = createAction(
    '[Document Checklist] Get Document Requirements Success',
    props<{
        documentRequirements: DocumentRequirement[];
        entityId: string;
        entityType: SystemEntity;
    }>()
);

export const getDocumentRequirementsFailure = createAction(
    '[Document Checklist] Get Document Requirements Failure',
    props<{
        entityId: string;
        entityType: SystemEntity;
        error: Error;
    }>()
);

export const filterDocumentRequirementsForClient = createAction(
    '[Document Checklist] Filter Document Requirements',
    props<{
        searchText: string;
        clientId: string;
    }>()
);

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { TranslationsService } from '@wdx/shared/utils';
import {
    IFormPendingChange,
    IFormPendingChangeSubForm,
} from './pending-changes.component.interface';

@Component({
    selector: 'wdx-ff-pending-changes',
    templateUrl: './pending-changes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingChangesComponent {
    @Input() pendingChanges: IFormPendingChange[] = [];
    get pendingChangesSubForm(): IFormPendingChangeSubForm {
        return this._pendingChangesSubForm;
    }
    @Input() set pendingChangesSubForm(subFormData: IFormPendingChangeSubForm) {
        this.subFormCount = subFormData ? Object.keys(subFormData).length : 0;
        this._pendingChangesSubForm = subFormData; 
    }
    @Input() showButton: boolean;

    @Output() wdxOnClick = new EventEmitter();

    private translationsService = inject(TranslationsService);

    readonly PENDING_TEXT = this.translationsService.getTranslationByKey(
        'STR_PENDING_CHANGE_ITEM'
    );

    readonly ICON_TRIANGLE_EXCLAMATION = 'triangle-exclamation';
    readonly PENDING_CHANGES_MAPPING: { [k: string]: string } = {
        '=0': 'is no pending change',
        '=1': this.translationsService.getTranslationByKey(
            'STR_PENDING_CHANGES_ACTIVE_SINGLE'
        ),
        other: this.translationsService
            .getTranslationByKey('STR_PENDING_CHANGES_ACTIVE_MULTIPLE')
            .replace('{count}', '#'),
    };
    readonly PENDING_CHANGES_SUB_FORM: { [k: string]: string } = {
        '=0': 'no',
        '=1': 'record being',
        other: 'records being',
    };
    subFormCount = 0;
    _pendingChangesSubForm: IFormPendingChangeSubForm;

    onClick(): void {
        this.wdxOnClick.emit();
    }
}

import { Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ReactiveFormLayoutAndDefinition } from '../models';

export interface IWrappedFormComponentConfig {
    component: Type<any>;
    inputs: {
        formInputData: ReactiveFormLayoutAndDefinition;
        formData?: any;
        formContext?: any;
    } & Record<string, unknown>;
}

export abstract class IWrappedFormComponentProvider {
    public readonly hideErrorMessages: boolean;
    public abstract getComponent$(
        formElement: ReactiveFormLayoutAndDefinition,
        formContext: any,
        formData: UntypedFormGroup
    ): Observable<{ component: Type<any>; inputs: Record<string, unknown> }>;
}

export type SubFormHeaderSubLevelType = [
    string | null,
    string | null,
    string | null
];

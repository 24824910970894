import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    inject,
} from '@angular/core';
import {
    FormArray,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    FilterFieldDefinition,
    FilterFieldType,
    FilterOperator,
    PossibleValue,
    QueryExpression,
    ViewType,
} from '@wdx/clmi/api-models';
import { TranslationsService, WdxDestroyClass } from '@wdx/shared/utils';
import { Observable, from } from 'rxjs';
import {
    debounceTime,
    filter,
    skip,
    switchMap,
    switchMapTo,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ASSIGN_TO_LOOKUP_DATA } from '../../../../constants/assign.constants';
import {
    FilterFieldDefinitionExt,
    QueryExpressionExt,
} from '../../../../models/filter-type-types.model';
import { DELETE_ITEM_RETURN_NEW_ARRAY } from '../../../../shared/helpers';
import {
    FilterQueryFacadeService,
    FilterQueryService,
    FilterViewFacadeService,
    QuerySectionsFacadeService,
} from '../shared/services';
import { FilterLookupService } from '../shared/services/filter-lookup/filter-lookup.service';
import {
    FILTER_QUERY_FAVOURITE,
    FILTER_QUERY_SEARCH,
} from '../shared/services/filter-query';
import {
    convertApiToBuilderFormat,
    convertBuilderToApiFormat,
    expressionValueValidator,
} from './builder.component.helpers';
import {
    ADD_FILTER_TYPE_LOOKUP,
    ADD_FILTER_TYPE_MULTI,
    IS_FAVOURITE_DEF,
    IS_FAVOURITE_QUERY,
    IS_SEARCH_DEF,
    IS_SEARCH_QUERY,
    NO_VALUE_OPERATORS,
} from './builder.static';

const SORT_DIRECTION = 'Ascending';
@Component({
    selector: 'clmi-query-builder',
    templateUrl: './builder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FilterLookupService],
})
export class BuilderComponent extends WdxDestroyClass implements OnInit {
    private filterQueryFacadeService = inject(FilterQueryFacadeService);
    private filterQueryService = inject(FilterQueryService);

    readonly FilterFieldType = FilterFieldType;
    readonly FILTER_OPERATOR_PREFIX = 'FILTER_OPERATOR_';
    readonly NO_VALUE_OPERATORS = NO_VALUE_OPERATORS;

    FilterOperator = FilterOperator;
    expressionViewData: FilterFieldDefinitionExt[] = [];
    arrayMultiSelectData: { [key: string]: { values: any[]; data: any[] } } =
        {};
    form: UntypedFormGroup;
    definitions$: Observable<FilterFieldDefinition[]> =
        this.filterQueryFacadeService
            .getFilterDefinition$(this.filterQueryService.queryType)
            .pipe(filter((res) => Boolean(res)));

    get expressions(): UntypedFormArray {
        return this.form.get('expressions') as UntypedFormArray;
    }

    get sortDirection(): UntypedFormControl {
        return this.form.get('sortDirection') as UntypedFormControl;
    }

    get sortBy(): UntypedFormControl {
        return this.form.get('sortBy') as UntypedFormControl;
    }

    get viewBuilderResults$(): Observable<boolean> {
        return this.filterQueryService.viewBuilderResults$;
    }

    @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
    public dynamicViewContainer: ViewContainerRef;

    constructor(
        private querySectionsFacadeService: QuerySectionsFacadeService,
        private filterViewFacadeService: FilterViewFacadeService,
        private translationService: TranslationsService,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private fLookupService: FilterLookupService
    ) {
        super();
        const editData = this.filterQueryService.viewInEdit;

        this.form = this.fb.group({
            expressions: this.fb.array(
                [],
                [
                    (fa: FormArray) =>
                        fa.length ? null : { conditionRequired: true },
                ]
            ),
            sortBy: [editData?.filter?.sortBy || null],
            sortDirection: [editData?.filter?.sortDirection || SORT_DIRECTION],
            name: [editData?.name || null, Validators.required],
        });

        if (editData) {
            this.setupFbArray(editData?.filter?.expressions);
        }

        if (!editData) {
            this.filterQueryService.filterHandler.setSearchTextWithoutEmitting(
                null
            );
            this.applyFilter();
        }

        this.filterQueryService.filterHandler.valuesChanged
            .pipe(debounceTime(150), takeUntil(this.destroyed$))
            .subscribe((filters: any) => {
                let hasSearch = false;
                let hasFavourite = false;

                this.expressions?.value?.map((expression, index) => {
                    if (expression.field === FILTER_QUERY_SEARCH) {
                        hasSearch = true;

                        if (!filters?.search) {
                            this.deleteExpression(index);
                        }

                        if (filters?.search) {
                            const formControl = this.expressions.at(index);
                            (formControl.get('values') as UntypedFormArray)
                                .at(0)
                                .setValue(filters?.search);
                            formControl.updateValueAndValidity();
                        }
                    }

                    if (expression.field === FILTER_QUERY_FAVOURITE) {
                        hasFavourite = true;
                        if (!filters?.isFavourite) {
                            this.deleteExpression(index);
                        }
                    }
                });

                if (filters?.search && !hasSearch) {
                    this.addExpression(
                        {
                            ...IS_SEARCH_DEF,
                        },
                        {
                            ...IS_SEARCH_QUERY,
                            operator: FilterOperator.Matches,
                            values: [filters?.search],
                        }
                    );
                }

                if (filters?.isFavourite && !hasFavourite) {
                    this.addExpression(
                        { ...IS_FAVOURITE_DEF },
                        {
                            ...IS_FAVOURITE_QUERY,
                            values: [filters?.isFavourite],
                        }
                    );
                }

                this.sortDirection.setValue(filters.sortDirection);
                this.sortDirection.updateValueAndValidity();

                this.sortBy.setValue(filters?.sortBy || null);
                this.sortDirection.updateValueAndValidity();

                this.cd.detectChanges();
                this.applyFilter();
            });

        /**
         * this.filterQueryService.updateTableHeadersSorting | set the correct data for the table headers
         * this.filterQueryService.updateTableHeader$ | this is used to listen for changes on the parent component
         */
        this.filterQueryService.updateTableHeadersSorting(editData);
        this.filterQueryService.updateTableHeader$.next({ isFavourite: false });
    }

    ngOnInit(): void {
        this.filterQueryService.viewBuilderResults$.next(false);
    }

    trackByDefinitionName(
        _: number,
        definition: FilterFieldDefinition
    ): string {
        return definition.name;
    }

    trackByOperatorsName(_: number, name: string): string {
        return name;
    }

    trackByArrayMultiSelectData(_: number, data: PossibleValue): string {
        return data.value;
    }

    trackByArrayMultiSelectValues(
        _: number,
        possibleValue: PossibleValue
    ): string {
        return possibleValue.value;
    }

    setupFbArray(data: QueryExpression[]): void {
        const builderFormatted = convertApiToBuilderFormat(data);
        builderFormatted?.map((queryExpression) => {
            if (queryExpression.field === FILTER_QUERY_SEARCH) {
                this.addExpression(IS_SEARCH_DEF, queryExpression);
                this.applyFilter();
            }

            if (queryExpression.field !== FILTER_QUERY_SEARCH) {
                this.definitions$
                    .pipe(
                        filter((res) => Boolean(res)),
                        take(1),
                        switchMap((res) =>
                            from(res).pipe(
                                filter((hasData) =>
                                    [
                                        hasData.name?.toLowerCase(),
                                        hasData.displayName?.value?.toLowerCase(),
                                    ].includes(
                                        queryExpression.field.toLowerCase()
                                    )
                                )
                            )
                        )
                    )
                    .subscribe((definitions) => {
                        this.addExpression(definitions, queryExpression);
                        this.applyFilter();
                    });
            }
        });
    }

    addNewQuery(definition: FilterFieldDefinition): void {
        this.addExpression(definition);
    }

    addExpression(
        definition: FilterFieldDefinitionExt | any,
        data?: QueryExpressionExt
    ): void {
        const formSubs = [];
        const formId = Date.now();
        const expressionForm = this.fb.group(
            {
                field: [definition?.name, Validators.required],
                operator: [
                    data?.operator || definition.operators[0],
                    Validators.required,
                ],
                values: this.fb.array([], []),
            },
            {
                validator: expressionValueValidator,
            }
        );

        const multiOperator = this.isMultiOperator(
            data?.operator || definition.operators[0]
        );

        if (ADD_FILTER_TYPE_MULTI.includes(definition.type)) {
            const operatorSub = expressionForm.controls.operator.valueChanges
                .pipe()
                .subscribe((operator: FilterOperator) => {
                    const evData = this.expressionViewData.find(
                        (evd) => evd.formId === formId
                    );
                    if (evData) {
                        const multiOperator = this.isMultiOperator(operator);
                        if (multiOperator !== evData.multiOperator) {
                            evData.multiOperator = multiOperator;
                            expressionForm.controls.values.setValue([[]]);
                        }
                    }
                });
            formSubs.push(operatorSub);
        }
        this.expressions.push(expressionForm);

        if (definition.type === FilterFieldType.Search) {
            const searchSub = expressionForm.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data) => {
                    this.filterQueryService.filterHandler.setSearchTextWithoutEmitting(
                        data.values[0]
                    );
                });
            formSubs.push(searchSub);
        }
        this.expressionViewData.push({
            ...definition,
            contextual: data?.contextual,
            formSubs,
            formId,
            multiOperator,
        });
        this.addFilterValue(this.expressions.length - 1, data, definition);
    }

    deleteExpression(index: number): void {
        const newArrayMultiSelectData: {
            [key: string]: { values: any[]; data: any[] };
        } = {};
        this.expressionViewData[index].formSubs?.forEach((sub) => {
            sub?.unsubscribe();
        });

        Object.keys(this.arrayMultiSelectData)?.map((key) => {
            const keyInt = parseInt(key, 10);
            if (index > keyInt) {
                newArrayMultiSelectData[keyInt] =
                    this.arrayMultiSelectData[key];
            }

            if (index < keyInt) {
                newArrayMultiSelectData[keyInt - 1] =
                    this.arrayMultiSelectData[key];
            }
        });

        this.arrayMultiSelectData = newArrayMultiSelectData;

        this.expressionViewData = [
            ...this.expressionViewData.slice(0, index),
            ...this.expressionViewData.slice(index + 1),
        ];

        const expression = this.expressions.at(index).get('field');

        if (expression.value === FILTER_QUERY_SEARCH) {
            this.filterQueryService.filterHandler.setSearchText(null);
        }

        if (expression.value === FILTER_QUERY_FAVOURITE) {
            this.filterQueryService.filterHandler.setIsFavourite(false);
        }

        this.expressions.removeAt(index);
    }

    clearForm(): void {
        this.expressions.clear();
        this.form.reset();
        this.sortDirection.setValue(SORT_DIRECTION, {
            emitEvent: false,
            emitModelToViewChange: false,
            emitViewToModelChange: false,
        });
        this.sortDirection.updateValueAndValidity();
        this.expressionViewData = [];
        this.arrayMultiSelectData = {};
        this.filterQueryService.builderQuery = null;
        this.filterQueryService.buildQuery$.next(null);
    }

    clearAndReturnToViews(): void {
        this.clearForm();
        this.filterQueryService.viewInEdit = null;
        this.filterQueryService.inBuilder = false;
        this.filterQueryService.selectView(this.filterQueryService.view);
        this.querySectionsFacadeService.toggleQueryBuilder();
    }

    applyFilter(): void {
        const APPLY_DATA = { ...this.form.value };
        delete APPLY_DATA.name;

        /**
         * In edit mode the views should have been updated
         * This is in new(create) mode. We'll need to add the contextual
         */
        if (!this.filterQueryService.viewInEdit) {
            APPLY_DATA.expressions = [
                ...APPLY_DATA.expressions,
                ...this.filterQueryService.contextual,
            ];
        }

        APPLY_DATA.expressions = convertBuilderToApiFormat(
            APPLY_DATA.expressions
        );

        this.filterQueryService.builderQuery = APPLY_DATA;
        this.filterQueryService.buildQuery$.next(APPLY_DATA);
    }

    saveView(): void {
        const VIEW_DATA = {
            ...this.filterQueryService?.viewInEdit,
            id:
                this.filterQueryService?.viewInEdit?.type === ViewType?.System
                    ? null
                    : this.filterQueryService?.viewInEdit?.id,
            filter: {
                expressions: [...this.form.value.expressions],
                sortBy: this.form.value.sortBy,
                sortDirection: this.form.value.sortDirection,
            },
            name: this.form.value.name,
        };

        /**
         * If there is contextual query, remove the contextual query before
         * saving to the database.
         */
        if (this.filterQueryService?.contextual?.length) {
            VIEW_DATA.filter.expressions = VIEW_DATA.filter.expressions.filter(
                (expression: QueryExpressionExt) => !expression.contextual
            );
        }

        VIEW_DATA.filter.expressions = convertBuilderToApiFormat(
            VIEW_DATA.filter.expressions
        );

        this.filterViewFacadeService.saveView(
            this.filterQueryService.viewType,
            VIEW_DATA
        );

        this.filterViewFacadeService
            .getSavedOrUpdatedView$()
            .pipe(
                skip(1),
                filter((res) => Boolean(res)),
                tap((view) => {
                    const FILTER = { ...view?.filter };
                    const EXPRESSIONS = Array.isArray(FILTER?.expressions)
                        ? // eslint-disable-next-line no-unsafe-optional-chaining
                          [...FILTER?.expressions]
                        : [];
                    if (this.filterQueryService?.contextual?.length) {
                        view = {
                            ...view,
                            filter: {
                                ...FILTER,
                                expressions: [
                                    ...EXPRESSIONS,
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    ...this.filterQueryService?.contextual,
                                ],
                            },
                        };
                    }

                    this.filterQueryService.view = view;
                }),
                switchMapTo(
                    this.filterViewFacadeService.getViewsForEntityType$(
                        this.filterQueryService.viewType,
                        this.filterQueryService?.contextual
                    )
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe((_) => {
                this.clearAndReturnToViews();
            });
    }

    getValues(index: number): UntypedFormArray {
        return this.expressions?.at(index)?.get('values') as UntypedFormArray;
    }

    newValue(data): UntypedFormControl {
        return new UntypedFormControl(data);
    }

    filterValues(filterIndex: number): UntypedFormArray {
        return this.expressions
            .at(filterIndex)
            .get('values') as UntypedFormArray;
    }

    addFilterValue(
        filterIndex: number,
        data: QueryExpression,
        definition: FilterFieldDefinition
    ) {
        if (data) {
            if (ADD_FILTER_TYPE_LOOKUP.includes(definition.type)) {
                this.addArrayData(filterIndex, data.values);
            } else {
                data.values.forEach((value) => {
                    this.filterValues(filterIndex).push(this.newValue(value));
                });
            }
        }

        if (typeof data === 'undefined') {
            const DEFAULT_VALUE = this.setDefaultValueByType();
            this.filterValues(filterIndex).push(this.newValue(DEFAULT_VALUE));
        }
    }

    setDefaultValueByType(): any {
        return null;
    }

    deleteExpressionValue(
        index: number,
        childIndex: number,
        val,
        prop = 'value'
    ): void {
        const ITEMS = this.arrayMultiSelectData[index];

        const itemIndex = this.arrayMultiSelectData[index]?.data.findIndex(
            (item) => item[prop] === val[prop]
        );

        if (prop === 'id') {
            this.arrayMultiSelectData[index] = {
                data: DELETE_ITEM_RETURN_NEW_ARRAY(
                    this.arrayMultiSelectData[index]?.data,
                    itemIndex
                ),
                values: DELETE_ITEM_RETURN_NEW_ARRAY(
                    this.arrayMultiSelectData[index]?.values,
                    itemIndex
                ),
            };
        } else {
            ITEMS.data[itemIndex] = { ...val, checked: false };
        }

        this.updateFormControls(
            index,
            childIndex,
            ITEMS.data[itemIndex].checked
        );
    }

    updateFormControls(index: number, childIndex: number, data: any): void {
        if (this.filterValues(index).at(childIndex)) {
            this.filterValues(index).at(childIndex).setValue(data);
        }

        if (!this.filterValues(index).at(childIndex)) {
            this.filterValues(index).push(this.newValue(data));
        }
    }

    addDynamicComponent(
        index: number,
        lookupInfo: FilterFieldDefinition
    ): void {
        const isMulti = false;
        const formInputData = {
            ...ASSIGN_TO_LOOKUP_DATA,
            label: this.translationService.getTranslationByKey(
                lookupInfo.displayName.key
            ),
            lookupSources: lookupInfo.lookupSources.map((lookupSource) => ({
                type: lookupSource,
            })),
        };

        this.fLookupService.dynamicViewContainer = this.dynamicViewContainer;
        this.fLookupService.config(
            {
                showReadout: false,
                isMulti,
            },
            {
                formInputData,
                initialValue: this.arrayMultiSelectData[index]
                    ? this.arrayMultiSelectData[index].data
                    : '',
            }
        );

        this.fLookupService.openLookUp();

        this.fLookupService.lookupComponentRef.instance.valueChanged
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
                this.addArrayData(index, data);
            });
    }

    addArrayData(index: number, values: any[]): void {
        if (values === null || values === undefined) {
            return;
        }
        /**
         * Check if this is an array that is returned if it's a multi lookup,
         * else it's a lookup that returns an object and not array
         */
        const RES = Array.isArray(values) ? values : [values];
        this.arrayMultiSelectData[index] = {
            data: [...RES],
            values: [...RES],
        };

        this.filterValues(index).clear();
        RES.forEach((value) => {
            this.filterValues(index).push(this.newValue(value));
        });
    }

    private isMultiOperator(operator: FilterOperator) {
        return ![FilterOperator.Equals, FilterOperator.NotEquals].includes(
            operator
        );
    }
}

import { Action, createReducer, on } from '@ngrx/store';
import { Notification, NotificationStatus } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as notificationsActions from './notifications.actions';

export interface State {
    notifications?: CrudStateObject<Notification>;
    unreadNotifications?: number;
    paginatedViewResponse?: CrudState<Notification>;
}

export const initialState: State = {
    notifications: {},
    unreadNotifications: 0,
    paginatedViewResponse: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        notificationsActions.getPage,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        notificationsActions.getPageSuccess,
        (state, props): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.notifications.paging,
                        combinedList: [
                            ...(props.reset
                                ? []
                                : state.notifications[GLOBAL_STATE_INDEX_ID]
                                      .infinity?.combinedList || []),
                            ...props.notifications.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        notificationsActions.getPageFailure,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        notificationsActions.setUnreadNotifications,
        (state, props): State => ({
            ...state,
            unreadNotifications: props.unreadNotifications,
        })
    ),

    on(
        notificationsActions.getForView,
        (state): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                isLoadingPage: true,
                hasLoadingPageError: false,
            },
        })
    ),

    on(
        notificationsActions.getForViewSuccess,
        (state, props): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                isLoadingPage: false,
                hasLoadingPageError: false,
                page: {
                    ...(state.paginatedViewResponse.page ?? {}),
                    paging: props.payload.paging,
                    results: props.payload.results,
                },
            },
        })
    ),

    on(
        notificationsActions.getForViewFailure,
        (state): State => ({
            ...state,
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                isLoadingPage: false,
                hasLoadingPageError: true,
            },
        })
    ),

    on(notificationsActions.markAsRead, (state, props): State => {
        const notificationsCombinedList = markNotificationsAsRead(
            props.notifications,
            state.notifications[GLOBAL_STATE_INDEX_ID]?.infinity?.combinedList
        );
        const paginatedViewResponseResults = markNotificationsAsRead(
            props.notifications,
            state.paginatedViewResponse?.page?.results
        );
        const uniqueIdsMarkedAsRead = [
            ...new Set([
                ...notificationsCombinedList.idsMarkedAsRead,
                ...paginatedViewResponseResults.idsMarkedAsRead,
            ]),
        ];
        const unreadNotifications = Math.max(
            state.unreadNotifications - uniqueIdsMarkedAsRead.length,
            0
        );
        return {
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    infinity: {
                        ...(state.notifications[GLOBAL_STATE_INDEX_ID]
                            ?.infinity ?? {}),
                        combinedList: notificationsCombinedList.notifications,
                        paging: state.notifications[GLOBAL_STATE_INDEX_ID]
                            ?.infinity?.paging,
                    },
                },
            },
            paginatedViewResponse: {
                ...(state.paginatedViewResponse ?? {}),
                page: {
                    ...(state.paginatedViewResponse?.page ?? {}),
                    paging: state.paginatedViewResponse?.page?.paging,
                    results: paginatedViewResponseResults.notifications,
                },
            },
            unreadNotifications,
        };
    })
);

export function markNotificationsAsRead(
    markAsRead: Notification[],
    state: Notification[]
): { notifications: Notification[]; idsMarkedAsRead: string[] } {
    const ids =
        markAsRead?.length && markAsRead.map((notification) => notification.id);
    const notifications: Notification[] = [...(state || [])];
    const idsMarkedAsRead: string[] = [];
    if (ids?.length && notifications?.length) {
        ids.forEach((id) => {
            const index = notifications.findIndex(
                (stateNotif) => stateNotif.id === id
            );
            if (index > -1) {
                const notification = notifications[index];
                if (notification.status !== NotificationStatus.Read) {
                    notifications[index] = {
                        ...notification,
                        status: NotificationStatus.Read,
                    };
                    if (!idsMarkedAsRead.includes(id)) {
                        idsMarkedAsRead.push(id);
                    }
                }
            }
        });
    }
    return { notifications, idsMarkedAsRead };
}

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}

import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ReactiveFormLayoutAndDefinition } from '../../../models';
import {
    CHECKBOX_CONTROL_TYPES,
    DATE_CONTROL_TYPES,
    FILE_UPLOAD_CONTROL_TYPES,
    INPUT_CONTROL_TYPES,
    MULTI_SELECT_CONTROL_TYPES,
    RADIO_BUTTONS_CONTROL_TYPES,
    SELECT_CONTROL_TYPES,
    WRAPPED_CONTROL_TYPES,
} from '../../../constants';
import { FormFieldType } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-ff-controls-mapper',
    templateUrl: './form-controls-mapper.component.html',
})
export class FormControlsMapperComponent {
    @Input() formElement: ReactiveFormLayoutAndDefinition;
    @Input() formContext: any;

    constructor(public controlContainer: ControlContainer) {}

    readonly CHECKBOX_CONTROL_TYPES = CHECKBOX_CONTROL_TYPES;
    readonly RADIO_BUTTONS_CONTROL_TYPES = RADIO_BUTTONS_CONTROL_TYPES;
    readonly DATE_CONTROL_TYPES = DATE_CONTROL_TYPES;
    readonly WRAPPED_CONTROL_TYPES = WRAPPED_CONTROL_TYPES;
    readonly INPUT_CONTROL_TYPES = INPUT_CONTROL_TYPES;
    readonly SELECT_CONTROL_TYPES = SELECT_CONTROL_TYPES;
    readonly MULTI_SELECT_CONTROL_TYPES = MULTI_SELECT_CONTROL_TYPES;
    readonly FILE_UPLOAD_CONTROL_TYPES = FILE_UPLOAD_CONTROL_TYPES;
    readonly FORM_FIELD_TYPE = FormFieldType;
}

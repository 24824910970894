<molecule-action-button
    *ngIf="actionButton?.dropdownMenu?.length"
    [actionButton]="actionButton"
    [btnClass]="btnClass"
    [size]="size"
    (selectMenuItem)="onImportIconClicked($event)"
></molecule-action-button>


<atom-modal-wrapper modalId="addToList" [modalTemplate]="addToListModal">
    <ng-template #addToListModal>
        <organism-modal modalTitle="Add to List">
            <molecule-info-card-search
                [mode]="INFO_CARD_SEARCH_MODE.Select"
                [infoCards]="lists$ | ngrxPush"
                [clientSideSearch]="true"
                [isLoading]="listsIsLoading$ | ngrxPush"
                [hasError]="listsHasError$ | ngrxPush"
                (valueChanged)="onAddToList($event)"
            ></molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SystemEntity, View } from '@wdx/clmi/api-models';
import { QueryType } from '@wdx/clmi/api-services/services';
import { BehaviorSubject } from 'rxjs';
import { QueryExpressionExt } from '../../../models/filter-type-types.model';
import { PaginationOptions } from '../../../models/pagination-options';
import { CONFIG_CONTEXTUAL } from '../../features/form-filters/shared/helper/helper-contextual';

@Injectable({ providedIn: 'root' })
export class RouteDataService {
    view: View;
    viewType: SystemEntity;
    queryType: QueryType;
    view$ = new BehaviorSubject<View>(null);
    paginationOptions: PaginationOptions;
    contextual?: QueryExpressionExt[] = [];

    resolveFromRoute(route: ActivatedRouteSnapshot) {
        this.viewType = route.data.viewType;
        this.queryType = route.data.queryType || route.data.viewType;
        this.paginationOptions = route.data.paginationOptions;
        this.contextual = CONFIG_CONTEXTUAL(route.data.contextual, route);
    }

    setView(view: View) {
        this.view = view;
        this.view$.next(view);
    }
}

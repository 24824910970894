import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as filterActions from '../../shared/features/form-filters';
import * as favouritesActions from './favourites.actions';
import { FavouritesService } from './favourites.service';

@Injectable()
export class FavouritesEffects {
    private actions$ = inject(Actions);
    private favouritesService = inject(FavouritesService);

    toggleFavourite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(favouritesActions.toggleFavourite),
            map((action) =>
                action.newValue
                    ? favouritesActions.favourite({
                          id: action.id,
                          entity: action.entity,
                          ...(action?.reload ? { reload: action.reload } : {}),
                      })
                    : favouritesActions.unfavourite({
                          id: action.id,
                          entity: action.entity,
                          ...(action?.reload ? { reload: action.reload } : {}),
                      })
            )
        )
    );

    favourite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(favouritesActions.favourite),
            mergeMap((action) =>
                this.favouritesService.favourite(action.id, action.entity).pipe(
                    switchMap(() => [
                        filterActions.updateAnyFilterProperty({
                            filterType: action.entity,
                            payload: { isFavourite: true },
                            id: action.id,
                        }),
                        favouritesActions.favouriteSuccess({
                            id: action.id,
                            entity: action.entity,
                            ...(action?.reload
                                ? { reload: action.reload }
                                : {}),
                        }),
                    ]),
                    catchError((error) =>
                        of(
                            favouritesActions.favouriteFailure({
                                id: action.id,
                                entity: action.entity,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    unfavourite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(favouritesActions.unfavourite),
            mergeMap((action) =>
                this.favouritesService
                    .unfavourite(action.id, action.entity)
                    .pipe(
                        switchMap(() => [
                            filterActions.updateAnyFilterProperty({
                                filterType: action.entity,
                                payload: { isFavourite: false },
                                id: action.id,
                            }),
                            favouritesActions.unfavouriteSuccess({
                                id: action.id,
                                entity: action.entity,
                                ...(action?.reload
                                    ? { reload: action.reload }
                                    : {}),
                            }),
                        ]),
                        catchError((error) =>
                            of(
                                favouritesActions.unfavouriteFailure({
                                    id: action.id,
                                    entity: action.entity,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}

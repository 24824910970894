import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { TranslateEnumPipe } from '@wdx/shared/utils';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomAsyncDataModule } from '../../atoms/atom-async-data/atom-async-data.module';
import { MoleculeInfoCardModule } from '../molecule-info-card/molecule-info-card.module';
import { MoleculeInputSearchModule } from '../molecule-input-search/molecule-input-search.module';
import { MoleculeInputWrapperModule } from '../molecule-input-wrapper/molecule-input-wrapper.module';
import { MoleculeInfoCardSearchComponent } from './molecule-info-card-search.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeInfoCardModule,
        MoleculeInputSearchModule,
        MoleculeInputWrapperModule,
        PipesModule,
        AtomIconButtonModule,
        MoleculeActionButtonModule,
        NgSelectModule,
        WdxIconModule,
        TranslateEnumPipe,
    ],
    declarations: [MoleculeInfoCardSearchComponent],
    exports: [MoleculeInfoCardSearchComponent],
})
export class MoleculeInfoCardSearchModule {}

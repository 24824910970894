import { QueryType } from '@wdx/clmi/api-services/services';

export const FILTER_QUERY_FAVOURITE = 'IsFavourite';
export const FILTER_HANDLER_FAVOURITE = 'isFavourite';
export const FILTER_HANDLER_SEARCH = 'search';
export const FILTER_QUERY_SEARCH = 'Search';
export const FILTER_QUERY_BATCH = 'BatchId';

export enum ActivitySelectType {
    OpenInbox = 'OpenInbox',
    CompletedInbox = 'CompletedInbox',
}

export type SelectType = QueryType | ActivitySelectType;

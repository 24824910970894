import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { FieldDefinitionOption } from '@wdx/shared/utils';
import { take, takeUntil } from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';
import { ContextualData, ExtendedFieldDefinitionOption } from '../../../models';
import { FormContextualDataService } from '../../../services';

let SELECT_ID_INDEX = 0;

@Component({
    selector: 'wdx-ff-select',
    templateUrl: './form-select-control.component.html',
})
export class FormSelectControlComponent
    extends BaseWdxFormControlClass
    implements OnInit, OnDestroy
{
    selectId: string;
    contextualData: ContextualData;
    loading = false;

    get entityId() {
        return this.formStaticService.formData?.entityId;
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private formContextualDataService: FormContextualDataService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.selectId = `select-${SELECT_ID_INDEX}`;
        SELECT_ID_INDEX++;
    }

    override setOptionsForSelectSource() {
        if (this.formElement?.contextualValues?.length) {
            this.setContextualData();
            this.setParentalControl();
        }
        this.setOptions();
    }

    setParentalControl() {
        this.isLoading(true);

        this.formElement?.contextualValues?.forEach((contextualValue) => {
            const form = this.formContextualDataService.useRootFormData(
                contextualValue
            )
                ? this.formStaticService.form
                : (this.controlContainer as FormGroupDirective)?.form;
            form.get(
                this.formContextualDataService.getPropName(contextualValue)
            )
                ?.valueChanges.pipe(takeUntil(this.destroyed$))
                .subscribe(() => {
                    this.isLoading(false);
                    this.setContextualData();
                    this.setOptions();
                });
        });
    }

    setOptions() {
        this.isLoading(true);

        if (
            this.contextualData?.lookups?.length ||
            this.contextualData?.context?.length
        ) {
            const { context, lookups } = this.contextualData;

            this.getOptionsForContextSelectSource$(lookups, context)
                .pipe(take(1))
                .subscribe((options) => {
                    this.isLoading(false);
                    this.validateValueExistsInOptions(options);
                    this.options$.next(options);
                });
        } else {
            const PARENT = this.contextualData
                ? this.formContextualDataService
                      .getContextualResults(this.contextualData)
                      .join(',')
                : null;
            const PARAMS = {
                ...(PARENT && {
                    parent: PARENT,
                }),
            };
            this.getOptionsForSelectSource$(PARAMS)
                .pipe(take(1))
                .subscribe((options) => {
                    this.isLoading(false);
                    this.validateValueExistsInOptions(options);
                    this.options$.next(options);
                });
        }
    }

    /**
     * Validates that the value exists in the options.
     * Removes the value by triggering a setValue on the form control.
     * Used to remove stale data from records.
     */
    validateValueExistsInOptions(options: ExtendedFieldDefinitionOption[]) {
        const value = this.formControl?.value;

        if (!value) {
            return;
        }

        const valuesToCheck = Array.isArray(value) ? value : [value];

        const filtered = options
            .filter((option) => valuesToCheck.includes(option.value))
            .map((option) => option.value);

        if (filtered.length !== valuesToCheck.length) {
            this.formControl?.setValue(filtered.length === 0 ? null : filtered);
        }
    }

    setContextualData(): void {
        this.contextualData = this.formContextualDataService.getContextualData(
            this.formElement?.contextualValues as string[],
            (this.controlContainer as FormGroupDirective)?.form.getRawValue(),
            this.formStaticService.form.getRawValue(),
            this.formStaticService.formDefinition?.schema,
            this.formElement?.contextualType
        );
    }

    isLoading(isLoading: boolean) {
        this.loading = isLoading;
    }

    compareOptionToValue = (option: FieldDefinitionOption, fieldValue: any) =>
        this.dynamicDataService.compareOptionValue(option.value, fieldValue);
}

import { ExportParams } from '../../shared/features/exports/models/exports.model';
import { createAction, props } from '@ngrx/store';
import { QueryType } from '@wdx/clmi/api-services/services';

export const doExport = createAction(
    '[Exports] Do Export',
    props<ExportParams>()
);

export const doExportSuccess = createAction(
    '[Exports] Do Export Success',
    props<{ queryType: QueryType }>()
);

export const doExportFailure = createAction(
    '[Exports] Do Export Failure',
    props<{ error: Error }>()
);

export const refreshExports = createAction('[Exports] Refresh Exports');

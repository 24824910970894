import { KeyValueObject } from '@wdx/shared/utils';

const DARK_THEME_BODY_BG: KeyValueObject<string> = {
    'body-bg': 'gray-800',
    'body-bg-rgb': 'gray-800-rgb',
    'body-bg-muted': 'gray-700',
    'body-bg-muted-rgb': 'gray-700-rgb',
    'body-bg-strong': 'gray-900',
    'body-bg-strong-rgb': 'gray-900-rgb',
};

const DARK_THEME_BODY_COLOR: KeyValueObject<string> = {
    'body-color': 'white',
    'body-color-rgb': 'white-rgb',
    'body-color-muted': 'gray-500',
    'body-color-muted-rgb': 'gray-500-rgb',
    'body-color-strong': 'gray-200',
    'body-color-strong-rgb': 'gray-200-rgb',
};

const DARK_THEME_LINKS: KeyValueObject<string> = {
    'link-color': 'primary-light',
    'link-color-hover': 'white',
};

const DARK_THEME_BORDERS: KeyValueObject<string> = {
    'border-color': 'gray-600',
};

const DARK_THEME_COMPONENTS: KeyValueObject<string> = {
    'component-active-bg': 'secondary-dark',
    'component-active-bg-rgb': 'secondary-dark-rgb',
    'component-active-color': 'secondary-dark-contrast',
};

const DARK_THEME_ACCESSIBILITY: KeyValueObject<string> = {
    'primary-accessible': 'primary-light',
    'secondary-accessible': 'secondary-light',
    'success-accessible': 'success-light',
    'warning-accessible': 'warning-light',
    'danger-accessible': 'danger-light',
    'info-accessible': 'info-light',
};

const DARK_THEME_UTILITIES: KeyValueObject<string> = {
    luminance: 'luminance-dark',
};

export const DARK_THEME_VARS: KeyValueObject<string> = {
    ...DARK_THEME_BODY_BG,
    ...DARK_THEME_BODY_COLOR,
    ...DARK_THEME_LINKS,
    ...DARK_THEME_BORDERS,
    ...DARK_THEME_COMPONENTS,
    ...DARK_THEME_ACCESSIBILITY,
    ...DARK_THEME_UTILITIES,
};
